import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const funLaunchApi = async (
  url: string,
  body: {
    gameId: string
    currency: string
    balance: number
    lang: string
    nickname?: string
    extraData?: object
    // playerId?: string
  }
) => {
  const data = JSON.stringify({
    ...body,
    extraData: {
      lang: body.lang,
      ...body.extraData,
    },
    // playerId,
  })

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
