import { GeneralTranslation } from 'parsroyal/types'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'

export const getTranslation = (
  key: string,
  generalTranslation: GeneralTranslation,
  fallbackValue?: string
) => {
  const array: string[] = key.split('.')

  let translation = generalTranslation

  for (const level of array) {
    translation = translation[level]

    // If translation is undefined, return original key
    if (translation === undefined) {
      return fallbackValue || key
    }
  }

  if (typeof translation === 'string') return translation
  return fallbackValue || key
}

export const useT = () => {
  const { generalConfig } = useAppSelector(getUi)
  const generalTranslation = generalConfig.translations || {} // Initialize with an empty object

  const t = (key: string, fallbackValue?: string) => {
    return getTranslation(key, generalTranslation, fallbackValue)
  }

  return { t }
}

export default useT
