import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { NavIndex } from '@shared/constants'
import { AlienContext } from 'App'
import { joinUsSectionApi } from 'apis/strapiApi'
import PlayDemoButton from 'parsroyal/components/PlayDemoButton/PlayDemoButton'
import StrapiButton from 'parsroyal/components/StrapiButton/StrapiButton'
import { Banner } from 'parsroyal/types'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getUi,
  showLoginModal,
  showMobileNavigation,
} from 'store/reducers/uiSlice'

const JoinSection = () => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useAppDispatch()
  const { generalConfig } = useAppSelector(getUi)
  const { locale } = useParams()
  const { i18n } = useTranslation()
  const alienContext = useContext(AlienContext)

  const [joinUsSection, setJoinUsSection] = useState<Banner>()

  useEffect(() => {
    const getJoinUsSection = async () => {
      const data = await joinUsSectionApi(locale)
      if (data) setJoinUsSection(data.data)
    }
    if (locale && generalConfig.availableLanguages?.includes(locale)) {
      getJoinUsSection()
    }
  }, [i18n.language, generalConfig.availableLanguages])

  if (!joinUsSection) return <Box sx={{ width: 1152, height: 308 }}></Box>

  const handleClick = () => {
    if (sm) {
      dispatch(showLoginModal())
    } else {
      dispatch(
        showMobileNavigation({
          open: true,
          index: NavIndex.Login,
        })
      )
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: 1,
        maxWidth: 1120,
        height: ['calc(115vw - 16px)', 451, 300],
        m: 'auto',
      }}
    >
      <Stack sx={{ height: 1, justifyContent: 'center' }}>
        {/* TODO: remove from imoon cdn */}
        {joinUsSection ? (
          <img
            src={
              sm
                ? joinUsSection.img?.bannerImg?.url
                : joinUsSection.img?.bannerImgMobile?.url
            }
            alt='/'
          />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{ position: 'relative', height: 1 }}
          />
        )}
        <Stack
          sx={{
            position: 'absolute',
            gap: 1.5,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: 1,
            width: 1,
          }}
        >
          <Typography
            variant={sm ? 'h3' : 'subHeadline'}
            sx={{ fontSize: { sm: 20, md: 26, lg: 34 } }}
          >
            {joinUsSection.headline}
          </Typography>

          {alienContext?.isSocketConnected ? (
            <PlayDemoButton
              variant='contained'
              size='medium'
              gameId='1001'
              slug='crashroyale'
              sx={{ fontSize: 18 }}
            />
          ) : (
            joinUsSection.button?.map((item) => (
              <StrapiButton
                {...item}
                key={item.id}
                size='medium'
                sx={{ '.MuiButton-root': { fontSize: 18 } }}
              />
            ))
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default JoinSection
