import { faClipboard, faCopy } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, Typography } from '@mui/material'
import { colors } from '@theme'
import { formatCurrencyAmount } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { showToast } from 'store/reducers/uiSlice'
import { CurrencySelect, CurrencySelectProps } from '../CurrencySelect'
import { PaymentGatewayProps } from '../PaymentGatewaySelect'

const CryptoDeposit = (props: PaymentGatewayProps & CurrencySelectProps) => {
  const dispatch = useAppDispatch()
  const { paymentGateways, currencies, player } = useAppSelector(getGlobal)
  const minAmount =
    paymentGateways?.CPG.depositLimitByCurrency?.USDT?.minAmount || 10
  const depositRateByCurrency = paymentGateways?.CPG.depositRateByCurrency
  const wallet = useAppSelector(getGlobal).player?.wallet
  const [address, setAddress] = useState('')
  // const address = wallet?.byCurrency['USDT'].address?.TRON
  // const address = 't'
  const [copied, setCopied] = useState(false)
  const activeCurrency = player?.activeCurrency || 'USDT'
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  const renderDepositRates = () => {
    const depositRateList = []
    if (depositRateByCurrency)
      for (const [key, value] of Object.entries(depositRateByCurrency)) {
        depositRateList.push(
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5,
              '.MuiTypography-root': {
                color: colors.gray[8],
              },
            }}
          >
            <Typography key={key} variant='bodyMedium' dir={dir}>
              {t('wallet.deposit_rate', '{{currency}} 1 = ').replace(
                '{{currency}}',
                key
              )}
            </Typography>
            <Typography dir={'ltr'} variant='bodyMedium'>
              <span style={{ display: 'inline-block' }}>
                {` ${t(`currencies.${value.toCurrency}`, value.toCurrency)} `}
              </span>{' '}
              {formatCurrencyAmount(value.rate, value.toCurrency, 8)}
            </Typography>
          </Box>
        )
      }

    return depositRateList
  }

  useEffect(() => {
    const currency = wallet?.byCurrency['USDT']
    if (currency) if (currency.address) setAddress(currency.address.TRON)
  }, [wallet])

  return (
    <Stack
      sx={{
        gap: 2.5,
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <>
        <CurrencySelect {...props} />

        <Stack
          sx={{
            p: 1,
            background: '#fff',
            borderRadius: 1.5,
            svg: { width: 135, height: 135 },
          }}
        >
          <QRCode value={address} level='H' />
        </Stack>
        <Stack sx={{ gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              py: 1,
              px: 1.5,
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer',
              borderRadius: 2,
              background: colors.gray[4],
              '&:hover': {
                unset: 'all',
                opacity: 0.8,
              },
              width: 225,
              wordBreak: 'break-word',
              svg: { fontSize: 16 },
            }}
            onClick={() => {
              setCopied(true)
              navigator.clipboard.writeText(address)

              dispatch(
                showToast({
                  show: true,
                  message: 'wallet.address_copied',
                  severity: 'success',
                })
              )
              setTimeout(() => {
                setCopied(false)
              }, 1000)
            }}
          >
            <Typography
              variant='caption'
              sx={{
                color: colors.gray[9],
                wordWrap: 'break-word',
              }}
            >
              {address}
            </Typography>
            <Stack sx={{ justifyContent: 'center' }}>
              <FontAwesomeIcon
                icon={copied ? faClipboard : faCopy}
                fixedWidth
                size='2xs'
                color={colors.secondary[5]}
              />
            </Stack>
          </Box>
        </Stack>
      </>
      <Stack sx={{ gap: 1 }}>
        <Typography sx={{ color: colors.gray[8] }} variant='bodyMedium'>
          {t('wallet.min_deposit').replace(
            '{{minAmount}}',
            `USDT ${minAmount}`
          )}
        </Typography>
        {renderDepositRates()}
      </Stack>
      <Box
        sx={{
          border: `1px solid ${colors.gray[4]}`,
          borderRadius: '12px',
          p: 1.5,
          background:
            'linear-gradient(344deg, #221C33 0%, rgba(42, 36, 58, 0.00) 100%);',
        }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 14,
            span: { color: colors.primary[4] },
          }}
        >
          {t('wallet.note')}
        </Typography>
      </Box>
    </Stack>
  )
}

export default CryptoDeposit
