import {
  Box,
  Button,
  Card,
  CardProps,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import { Interweave } from 'interweave'
import HeroBanner from 'parsroyal/components/HeroBanner/HeroBanner'
import PageContent from 'parsroyal/components/PageContent/PageContent'
import { LiveTournamentStatus } from 'parsroyal/components/Tournament/LiveTournamentStatus'
import { Reward, TournamentSection } from 'parsroyal/types'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi } from 'store/reducers/uiSlice'

const Tournament = () => {
  const { t } = useT()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const { locale } = useParams()
  const { tournamentSection, generalConfig } = useAppSelector(getUi)

  if (!tournamentSection) return <></>
  return (
    <>
      <Helmet>
        <title>Tournaments - {generalConfig.siteTitle}</title>
      </Helmet>
      <Box>
        <Stack>
          <HeroBanner
            images={{
              desktop: tournamentSection?.img?.bannerImg?.url,
              mobile: tournamentSection?.img?.bannerImgMobile?.url,
            }}
            sx={{ background: `#04020B` }}
          />
        </Stack>
        <PageContent>
          <Stack
            sx={{
              flexDirection: ['column', null, 'row'],
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3,
              '& > *:not(:last-child)': {
                mb: { md: 3 },
                marginInlineEnd: { xs: 3, md: 0 },
              },
              '&>*': {
                flex: 1,
              },
            }}
          >
            {sm ? (
              <ContentDesktop tournamentSection={tournamentSection} />
            ) : (
              <ContentMobile tournamentSection={tournamentSection} />
            )}
          </Stack>
          {<LiveTournamentStatus />}
          {/* <TournamentHistory /> */}
        </PageContent>
      </Box>
    </>
  )
}

interface ContentProps {
  tournamentSection: TournamentSection
}

const ContentDesktop = (props: ContentProps) => {
  const global = useAppSelector(getGlobal)
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const navigate = useNavigate()

  return (
    <Stack
      sx={{
        flexDirection: ['column-reverse', null, 'row'],
        '& > *:not(:last-child)': {
          marginInlineEnd: { md: 3 },
          mb: { xs: 3, md: 0 },
        },
      }}
    >
      <Stack sx={{ flex: 1 }}>
        <RankTable />
      </Stack>
      <Stack
        sx={{
          flex: 1,
          '& > *:not(:last-child)': {
            mb: 3,
          },
        }}
      >
        <Stack sx={{ color: colors.gray[9] }}>
          {props.tournamentSection.description && (
            <Interweave content={props.tournamentSection.description} />
          )}
        </Stack>
        <Button
          variant='contained'
          sx={{ py: 2 }}
          disabled={!props.tournamentSection.button?.game?.slug}
          onClick={() =>
            navigate(
              `/${i18n.language}/play/${props.tournamentSection.button?.game?.slug}`
            )
          }
        >
          {t('tournaments.enter_game')}
        </Button>
        {props.tournamentSection.img1?.url ? (
          <img
            src={props.tournamentSection.img1?.url}
            style={{ width: '100%', height: 'auto' }}
            alt=''
          />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{
              position: 'relative',
              width: '100%',
              height: '139px',
            }}
          />
        )}
        <Box
          sx={{
            background: colors.gray[3],
            p: 3,
            borderRadius: 4,
            textAlign: 'center',
          }}
        >
          <Typography
            variant={dir === 'rtl' ? 'body' : 'bodyBold'}
            sx={{ textAlign: 'center', color: colors.primary[4] }}
          >
            {props.tournamentSection.note}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

const ContentMobile = (props: ContentProps) => {
  const global = useAppSelector(getGlobal)
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const navigate = useNavigate()

  return (
    <Stack sx={{ flexDirection: 'column', gap: 3 }}>
      <Stack sx={{ flex: 1 }} gap={3}>
        <Stack sx={{ color: colors.gray[9] }}>
          {props.tournamentSection.description && (
            <Interweave content={props.tournamentSection.description} />
          )}
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <RankTable />
        </Stack>
        {props.tournamentSection ? (
          <img src={props.tournamentSection.img1?.url} />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{ position: 'relative', height: '113px' }}
          />
        )}
        <Box
          sx={{
            background: colors.gray[3],
            p: 3,
            borderRadius: 4,
            textAlign: 'center',
          }}
        >
          <Typography
            variant='bodyBold'
            sx={{ textAlign: 'center', color: colors.primary[4] }}
          >
            {props.tournamentSection.note}
          </Typography>
        </Box>
        <Button
          variant='contained'
          onClick={() =>
            navigate(
              `/${i18n.language}/play/${props.tournamentSection.button?.game?.slug}`
            )
          }
        >
          {t('tournaments.enter_game')}
        </Button>
      </Stack>
    </Stack>
  )
}

interface RankProps extends Reward {
  background?: string
  badge?: string
}

const Rank = (props: RankProps & CardProps) => {
  const { rankIndex, rank } = props
  const global = useAppSelector(getGlobal)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const reduceRankIndexSize = (rankIndex || '').length > 3
  const reduceRankSize = (rank || '').length > 12

  return (
    // TODO: Reduce font weight for farsi
    <Card sx={{ borderRadius: 2 }}>
      <Box
        sx={{
          display: 'flex',
          height: 42,
          alignItems: 'center',
          gap: 1,
          p: 1,
          background: props.background,
          '.MuiTypography-root': {
            fontWeight: dir === 'rtl' ? 500 : 700,
          },
          ...props.sx,
        }}
      >
        <Box sx={{ display: 'flex', width: 32, justifyContent: 'center' }}>
          {props.badge ? (
            <img src={props.badge} alt='' />
          ) : (
            props?.rankIndex && (
              <Typography
                className='rank--number'
                sx={{ ...(reduceRankIndexSize && { fontSize: 9 }) }}
              >
                {props.rankIndex}
              </Typography>
            )
          )}
        </Box>
        <Box
          sx={{
            borderLeft: `1px dotted ${colors.primary[5]} `,
            height: '30px',
            p: 0.5,
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            paddingInlineEnd: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: [12, 16],
              ...(reduceRankSize && {
                fontSize: { xs: 11, sm: 13, lg: 16 },
              }),
            }}
          >
            {props.rank}
            <span style={{ marginInlineEnd: '4px' }}>:</span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              '.MuiTypography-root': {
                fontSize: [13, 16],
                ...(reduceRankIndexSize && {
                  fontSize: { xs: 13, sm: 'initial' },
                }),
              },
            }}
          >
            <Typography>
              <span style={{ display: 'inline-block' }}>{props.prize}</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

const RankTable = () => {
  const { tournamentSection } = useAppSelector(getUi)
  const { locale } = useParams()

  if (!tournamentSection?.rewardList)
    return <Skeleton variant='rectangular' sx={{ flex: 1 }}></Skeleton>

  const customRankStyles: RankProps[] = [
    {
      badge: tournamentSection.rankBadgeList?.[0].url,
      background:
        'linear-gradient(90.34deg, rgba(255, 199, 0, 0) 52.72%, #FFC700 118.7%), #332C4A',
    },
    {
      badge: tournamentSection.rankBadgeList?.[1].url,
      background:
        'linear-gradient(270.17deg, #393939 -7.85%, rgba(160, 159, 157, 0.863636) 0.71%, rgba(142, 142, 142, 0) 54.89%, rgba(124, 124, 124, 0) 101.66%), #332C4A',
    },
    {
      badge: tournamentSection.rankBadgeList?.[2].url,
      background:
        ' linear-gradient(270.78deg, #744111 -19.01%, rgba(189, 102, 53, 0.863636) -10.91%, rgba(252, 123, 82, 0) 40.44%, rgba(124, 124, 124, 0) 84.75%), #332C4A',
    },
  ]

  return (
    <Stack
      sx={{
        '& > *:not(:last-child)': {
          mb: 1,
        },
      }}
    >
      {tournamentSection?.rewardList?.map((item, index) => (
        <Rank {...item} {...customRankStyles[index]} key={index} />
      ))}
    </Stack>
  )
}

export default Tournament
