import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

// Import Swiper styles
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '@theme'
import { heroSliderListApi } from 'apis/strapiApi'
import { Slider } from 'parsroyal/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import B2BContainer from '../SliderContainer/SliderContainer'
import StrapiButton from '../StrapiButton/StrapiButton'
import useT from 'hooks/useTranslation'

const HeroSlider = () => {
  const theme = useTheme()
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const { locale } = useParams()
  const [heroSliderList, setHeroSliderList] = useState<Slider[]>()
  const height = ['143.55vw', 174.13, 270.86, 367.59, 445]

  //TODO: Use SWR to fetch data
  useEffect(() => {
    const getSliders = async () => {
      const data = await heroSliderListApi(locale)
      if (data) setHeroSliderList(data.data)
    }
    getSliders()
  }, [locale])

  const [swiper, setSwiper] = useState(useSwiper())

  useEffect(() => {
    swiper && swiper.changeLanguageDirection(dir)
  }, [i18n.language])

  if (!heroSliderList)
    return (
      <Skeleton
        variant='rectangular'
        sx={{
          height,
        }}
      ></Skeleton>
    )

  return (
    <Box
      sx={{
        height,
        background: `linear-gradient(0deg, #04020B  60%, #04020B 84.5%, #110C20 60%)`,
        '.swiper-wrapper': {
          alignItems: 'end',
        },

        '&:hover .swiperNav': {
          '.MuiIconButton-root': {
            border: `1px solid ${colors.gray[12]}`,
            path: {
              color: colors.gray[12],
              opacity: 1,
            },
          },
        },

        '.swiper-pagination': {
          bottom: { xs: 20, sm: 1, md: 2, lg: 12 },
          maxHeight: '21px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&-bullet': {
            backgroundColor: colors.gray[12],
            opacity: 1,
            width: [8, 6, 8],
            height: [8, 6, 8],
            '&-active': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },

        '.swiperNav': {
          display: ['none', null, null, 'flex'],
          position: 'absolute',
          top: [null, null, null, 185, 225],
          zIndex: 1,
          width: 50,
          height: 50,

          '.MuiIconButton-root': {
            height: 50,
            borderRadius: '50%',
            border: `1px solid ${colors.gray[5]} `,
            path: {
              color: colors.gray[5],
            },
          },

          '&--prev': { left: 70 },
          '&--next': { right: 70 },
        },
        ...(!heroSliderList[0]?.backgroundImg && {
          height,
        }),
      }}
    >
      {heroSliderList.length > 1 && (
        <Stack
          sx={{
            maxWidth: theme.breakpoints.values.xl,
            position: 'relative',
            zIndex: 2,
            m: 'auto',
          }}
        >
          <Stack className='swiperNav swiperNav--next'>
            <IconButton
              onClick={() =>
                dir === 'rtl' ? swiper.slidePrev() : swiper.slideNext()
              }
            >
              <FontAwesomeIcon icon={faChevronRight} fontSize={16} />
            </IconButton>
          </Stack>
          <Stack className='swiperNav swiperNav--prev'>
            <IconButton
              onClick={() =>
                dir === 'rtl' ? swiper.slideNext() : swiper.slidePrev()
              }
            >
              <FontAwesomeIcon fixedWidth icon={faChevronLeft} fontSize={16} />
            </IconButton>
          </Stack>
        </Stack>
      )}
      <Swiper
        modules={[Pagination]}
        onSwiper={(swiper) => {
          setSwiper(swiper)
          swiper.changeLanguageDirection(dir)
        }}
        pagination={{ clickable: true }}
        grabCursor
        loop
        autoplay={{ delay: 50 }}
        style={{
          height: '100%',
          // backgroundColor: colors.gray[2],
        }}
      >
        {heroSliderList.map((item) => (
          <SwiperSlide key={item.id}>
            <Slide {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

const Slide = (props: Slider) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const hideSubHeadline = useMediaQuery(theme.breakpoints.between('sm', 'lg'))

  // const path = `${cdnUrlB2c}home/slider/${props.slug}`
  return (
    <B2BContainer
      sx={{
        width: '100%',
        height: 1,
        display: 'flex',
        position: 'relative',
        '.banner': { width: 1 },
        textAlign: 'center',
        '.MuiTypography-root': {
          color: colors.gray[12],
        },

        '.MuiSkeleton-root': {
          height: 1,
        },
      }}
    >
      <Skeleton
        variant='rectangular'
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          // height: '50%',
          zIndex: 4,
        }}
      />
      <Box sx={{ width: 1, height: 1, img: { height: 1 }, zIndex: 5 }}>
        <img
          src={sm ? props.backgroundImg?.url : props.backgroundImgMobile?.url}
          className='banner'
          alt=''
          style={{
            zIndex: 5,
          }}
        />
      </Box>
      <Stack
        sx={{
          position: 'absolute',
          alignItems: 'center',
          gap: 1.5,
          // bottom: [48, 10, 10, 10, 24],
          bottom: { xs: 48, sm: 18, md: 14, xl: 24 },
          width: 1,
          img: {
            width: ['80vw', 285, 120, 200, 285],
            maxWidth: 285,
          },

          transform: {
            xs: 'initial',
            sm: 'scale(0.75)',
            md: 'initial',
          },
        }}
      >
        <Stack
          sx={{
            gap: 1.25,
            position: 'absolute',
            bottom: [0, -12, 10],
            ...(props.position === 'LEFT' && {
              left: ['initial', '1vw', 100, 190, 254],
              right: 'initial',
            }),
            ...(props.position === 'RIGHT' && {
              right: ['initial', '-1vw', 90, 170, 238],
              left: 'initial',
            }),
            '.MuiButton-root': { minWidth: 132 },
          }}
        >
          {props.headline && (
            <Typography variant='headline'>{props.headline}</Typography>
          )}
          {/* Hide text if there's not enough space */}
          {props.subHeadline && !hideSubHeadline && (
            <Typography
              variant='headline'
              sx={{ fontSize: 32, fontWeight: 600 }}
            >
              {props.subHeadline}
            </Typography>
          )}
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1.5,
              justifyContent: 'center',
            }}
          >
            {props.button?.map((item, index) => (
              <StrapiButton
                key={index}
                {...props}
                {...item}
                size='large'
                sx={{
                  zIndex: 10,
                  '.MuiButton-root': {
                    height: [48, 40, 48],
                    padding: '6px 14px',
                  },
                  ...(item.position === 'LEFT' && {
                    left: ['initial', '11.9vw', 140, 230, 300],
                  }),
                  ...(item.position === 'RIGHT' && {
                    right: ['initial', '15vw', 168, 275, 330],
                  }),
                }}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </B2BContainer>
  )
}

export default HeroSlider
