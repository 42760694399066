import { refReplacer } from './helpers'

interface Log {
  event: string
  data: any
  time: number // Use number for epoch timestamp
}

// Add Log {title and payload, with timestamp autoGenerated}
export const addLog = (event: string, data: any) => {
  const localLogs = localStorage.getItem('logs')
  const newLog: Log = {
    event,
    data,
    time: Date.now(),
  }

  // Check if localLogs exist
  if (localLogs) {
    const parsedLogs: Log[] = JSON.parse(localLogs)
    const updatedLogs = [...parsedLogs, newLog]
    localStorage.setItem('logs', JSON.stringify(updatedLogs))
  } else {
    localStorage.setItem('logs', JSON.stringify([newLog]))
  }
}

// Read available logs, update it and store it back to storage
export const readLogs = (): Log[] => {
  const localLogs = localStorage.getItem('logs')
  const parsedLogs: Log[] = localLogs ? JSON.parse(localLogs) : []

  // Sort logs by latest time
  const sortedLogs = parsedLogs.sort((a, b) => b.time - a.time)

  return sortedLogs
}

// Clear logs from storage
export const clearLogs = () => {
  localStorage.removeItem('logs')
}
