import { Button, ButtonProps, Stack, TextField } from '@mui/material'
import useT from 'hooks/useTranslation'
import { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ModalComponent } from '../Modals'
interface PlayDemoButtonProps {
  slug: string
}

// TODO: Refactor code, clean up toast and verification key states
const FunPlayButton = (props: PlayDemoButtonProps & ButtonProps) => {
  const { t } = useT()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { locale } = useParams()

  // TODO: Create a custom hook to detect if user is connected to socket
  const handleClick = () => {
    navigate(`/${locale || 'en'}/play/${props.slug}?funPlay=true`)
  }

  const button = () => {
    return (
      <Button
        variant={props.variant || 'contained'}
        color={'secondary'}
        size={props.size}
        sx={{ ...props.sx }}
        fullWidth={props.fullWidth}
        onClick={handleClick}
      >
        {props.children || t('general.fun_play')}
      </Button>
    )
  }

  return (
    <NavLink to={`/${locale || 'en'}/play/${props.slug}?funPlay=true`}>
      {button()}
      <ModalComponent open={open} onClose={() => setOpen(false)}>
        <Stack>
          <TextField />
        </Stack>
      </ModalComponent>
    </NavLink>
  )
}

export default FunPlayButton
