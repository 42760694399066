import {
  faInstagram,
  faLinkedin,
  faSquareYoutube,
  faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { colors } from '@theme'
import { Interweave } from 'interweave'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'
import FooterNavList from './FooterNavList'
import useT from 'hooks/useTranslation'

const footerNavList = [
  {
    title: 'nav.support',
    list: [
      { label: 'nav.faq', href: 'faq', type: 'route' },
      {
        label: 'nav.crash_game_guide',
        href: 'crash-game-guide',
        type: 'route',
      },
      // {
      //   label: 'nav.live_support',
      //   Component: {
      //     Modal: LiveSupportModal,
      //     DrawerContent: <LiveSupport />,
      //     drawerValue: 4,
      //   },
      // },
    ],
  },
  {
    title: 'nav.legal',
    list: [
      {
        label: 'nav.terms_conditions',
        href: 'terms-and-conditions',
        type: 'route',
      },
      { label: 'nav.privacy_policy', href: 'privacy', type: 'route' },
    ],
  },
  {
    title: 'nav.services',
    list: [
      { label: 'nav.tournaments', href: 'tournament', type: 'route' },
      // {
      //   label: 'nav.affiliate_program',
      //   href: 'affiliate-program',
      // },
    ],
  },
  {
    title: 'nav.about_us',
    list: [
      { label: 'nav.about_us', href: 'about-us', type: 'route' },
      {
        label: 'nav.gambling_awareness',
        href: 'gambling-awareness',
        type: 'route',
      },
      { label: 'nav.fairness', href: 'provably-fair', type: 'route' },
    ],
  },
]

const Footer = () => {
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const { generalConfig, footer } = useAppSelector(getUi)
  const brand = generalConfig.brand
  const { locale } = useParams()

  const socialList = [
    { icon: faInstagram, url: brand?.socialMediaUrls?.instagram },
    { icon: faLinkedin, url: brand?.socialMediaUrls?.linkedIn },
    { icon: faTelegramPlane, url: brand?.socialMediaUrls?.telegram },
    { icon: faSquareYoutube, url: brand?.socialMediaUrls?.youtube },
  ]

  return (
    <Box
      id='FooterBox'
      sx={{
        textAlign: 'center',
        display: 'flex',
        py: 6,
        mb: { xs: 6, lg: 0 },
        background:
          'linear-gradient(180deg, #1D1929 0%, rgba(29, 25, 41, 0) 100%)',
      }}
    >
      <Container maxWidth='lg'>
        <Stack
          sx={{
            alignItems: 'center',
            '& > *:not(:last-child)': {
              mb: 3,
            },
          }}
        >
          <Stack
            sx={{
              width: 141,
            }}
          >
            <img src={brand?.logo?.url} alt='' />
          </Stack>
          <Grid
            container
            sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {footer.footerNavList?.map((item) => (
              <Grid item xs={6} md={3} key={item.id}>
                <FooterNavList {...item} />
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ width: 1, borderWidth: 1, borderStyle: 'dashed' }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              '& > *:not(:last-child)': {
                marginInlineEnd: 0.75,
              },
            }}
          >
            {brand?.licenseLogos?.map((item) => (
              <LogoItem src={item.url} key={item.id} />
            ))}
          </Box>
          {footer.text && (
            <Typography
              variant='bodySmall'
              color={colors.primary[12]}
              sx={{
                ...(dir === 'rtl'
                  ? { lineHeight: 2 }
                  : { lineHeight: 'initial' }),
              }}
            >
              <Interweave content={footer.text} />
            </Typography>
          )}
          <Stack>
            <Typography variant={dir === 'rtl' ? 'body' : 'bodyBold'}>
              {t('footer.follow')}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1.5 }}>
              {socialList.map((item, index) => (
                <Box key={index}>
                  {item.url ? (
                    <IconButton
                      sx={{ width: 55, height: 55 }}
                      href={item.url}
                      target='_blank'
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        fontSize={36}
                        icon={item.icon}
                        color={colors.gray[7]}
                      />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Box>
              ))}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

interface LogoItemProps {
  src: string
}

const LogoItem = (props: LogoItemProps) => {
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: [60, 80, 100],
        height: [60, 80, 100],
        borderRadius: 4,
        background:
          'linear-gradient(295.7deg, #2A243A 49.91%, rgba(42, 36, 58, 0) 97.93%)',
        maxWidth: 1,
        img: {
          width: [35, 50, 60],
        },
      }}
    >
      <img src={props.src} alt='' />
    </Card>
  )
}

export default Footer
