import badgeHot from '@assets/badge_hot.png'
import badgeNew from '@assets/badge_new.png'
import badgeSoon from '@assets/badge_soon.png'
import {
  Box,
  Card,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import useT from 'hooks/useTranslation'
import { Game } from 'parsroyal/types'
import { useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi } from 'store/reducers/uiSlice'
import FunPlayButton from '../FunPlayButton/FunPlayButton'
import PlayDemoButton from '../PlayDemoButton/PlayDemoButton'

export type GameId = { alien: string; b2c: string }
export interface GameCardProps {
  title: string
  rtp?: number
  releaseDate?: Date
  gameId?: string
  alt?: string
  slug: string
  tags?: {
    new?: boolean
    comingSoon?: boolean
    hot?: boolean
  }
}

// TODO: Add transition on hover
const sx: SxProps<Theme> = {
  position: 'relative',

  '.MuiCard-root': {
    display: 'flex',
    borderRadius: ['6px', '8px', '12px'],
    overflow: 'hidden',
    background: 'none',
    height: ['calc(57vw - 29.5px)', 135, 160, 226.6, 240],
    img: {
      maxWidth: 1,
      zIndex: 1,
      WebkitTransform: 'translate3d(0,0,0)',
      overflow: 'hidden',
      borderRadius: ['6px', '8px', '12px'],
    },
  },

  '.MuiSkeleton-root': {
    height: 1,
    position: 'absolute',
    top: 0,
    borderRadius: ['6px', '8px', '12px'],
    zIndex: 0,
  },

  ':hover': {
    '.game-card__hover': { opacity: 1 },
  },

  '.game-card__badge': {
    position: 'absolute',
    width: 31,
    left: 12,
    top: -5,
    zIndex: 2,
    WebkitTransform: 'translate3d(0,0,0)',
    pointerEvents: 'none',
  },

  '.game-card__hover': {
    background: '#1D1929EB',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 1,
    height: 1,
    opacity: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '.MuiButton-root': {
      borderRadius: '6px',
    },
    transition: 'opacity 100ms ease-in-out',
  },
}

export const GameCard = (props: Game) => {
  const theme = useTheme()
  const { t } = useT()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [loading, setLoading] = useState(true)
  const global = useAppSelector(getGlobal)
  const { generalConfig } = useAppSelector(getUi)
  const [showButtons, setShowButtons] = useState(false)

  const cardRef = useRef<HTMLDivElement | null>(null) // Ref for card element

  // Add a click event listener to the document body
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        cardRef.current &&
        !(e.target instanceof Node && cardRef.current.contains(e.target))
      ) {
        // Click occurred outside of the card, hide the buttons
        setShowButtons(false)
      }
    }

    document.body.addEventListener('click', handleClickOutside)

    return () => {
      // Remove the event listener when the component unmounts
      document.body.removeEventListener('click', handleClickOutside)
    }
  }, []) // Empty dependency array means this effect runs once on mount

  // TODO: Create a custom hook to detect if user is connected to socket
  const handleClick = () => {
    setShowButtons((prev) => !prev)
  }

  if (!props.coverImg)
    return (
      <Box sx={sx}>
        <Card>
          <Skeleton variant='rectangular' />
        </Card>
      </Box>
    )

  return (
    <Box
      sx={{
        '&:hover, &:focus': {
          '.game-card__badge': {
            display: showButtons || lg ? 'none' : 'block',
          },
        },
        ...sx,
      }}
    >
      {props.tags && (
        <img
          className='game-card__badge'
          src={
            props.tags === 'NEW'
              ? badgeNew
              : props.tags === 'HOT'
              ? badgeHot
              : badgeSoon
          }
          alt='badge'
        />
      )}

      <Card
        elevation={4}
        onClick={handleClick}
        sx={{
          sx,
        }}
        ref={cardRef} // Attach the ref to the card element
      >
        <Skeleton
          variant='rectangular'
          sx={{
            sx,
          }}
        />
        {props.coverImg && (
          <img
            src={props.coverImg.url}
            alt={props.coverImg.alternativeText || ''}
            onLoad={() => setLoading(false)}
            // style={{ width: '100%' }}
          />
        )}
      </Card>

      {(showButtons || lg) && (
        <Card
          className='game-card__hover'
          onClick={handleClick}
          sx={{ height: 'auto !important' }}
        >
          <Stack gap={1}>
            <Typography
              variant='subtitle1'
              sx={{
                fontFamily: '"Nunito Sans", sans serif !important',
                fontSize: ['initial', '14px', 'initial'],
              }}
            >
              {props.title}
            </Typography>

            {props.gameId && (
              <Stack sx={{ alignItems: 'center' }}>
                {generalConfig.showFunLaunch && (
                  <FunPlayButton
                    {...props}
                    slug={props.slug}
                    sx={{
                      flex: 0.3,
                      width: [96, 90, 96],
                      mb: 1,
                    }}
                    variant='contained'
                    size='small'
                    color='primary'
                    fullWidth
                  />
                )}
                <PlayDemoButton
                  {...props}
                  slug={props.slug}
                  gameId={props.gameId}
                  sx={{
                    flex: 0.3,
                    width: [96, 90, 96],
                  }}
                  variant='contained'
                  size='small'
                  color='primary'
                  fullWidth
                />
              </Stack>
            )}
          </Stack>
        </Card>
      )}
    </Box>
  )
}
