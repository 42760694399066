import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  BoxProps,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
interface CollectionProps extends PropsWithChildren {
  title: string
  icon: IconDefinition
  subtitle?: string
  viewAllUrl?: string
}

const sx: SxProps<Theme> = {
  position: 'relative',
  '.collection__header': {
    mb: 1.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.collection__title': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    a: {
      fontSize: 12,
      fontWeight: 700,
      textDecoration: 'none',
      color: colors.gray[8],
      ':hover': { color: 'white' },
    },
  },
}

const Section = (props: CollectionProps & BoxProps) => {
  const { title, icon, subtitle, viewAllUrl } = props
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  const theme = useTheme()
  return (
    <Box dir={dir} sx={{ ...sx, ...props.sx }}>
      <Stack className='collection__header'>
        <Box>
          <Stack className='collection__title'>
            <FontAwesomeIcon icon={icon} color={theme.palette.primary.main} />
            <Typography
              variant={dir === 'rtl' ? 'body' : 'bodyBold'}
              sx={{ fontSize: 18, marginInlineStart: 1.75 }}
            >
              {t(title)}
            </Typography>
          </Stack>
          {subtitle && (
            <Typography variant='subHeadline' sx={{ color: colors.gray[8] }}>
              {t(subtitle)}
            </Typography>
          )}
        </Box>
        {viewAllUrl && <Link to={viewAllUrl}>{t('section.view_all')}</Link>}
      </Stack>
      {props.children}
    </Box>
  )
}

export default Section
