import {
  Box,
  BoxProps,
  Container,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import B2BContainer from '../SliderContainer/SliderContainer'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useState } from 'react'
import useT from 'hooks/useTranslation'

interface HeroBannerProps {
  images: { desktop?: string; mobile?: string }
  title?: string
  loading?: boolean
  setLoading?: Dispatch<SetStateAction<boolean>>
}

const HeroBanner = (props: HeroBannerProps & BoxProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [loading, setLoading] = useState(true)

  return (
    <B2BContainer
      sx={{
        img: { position: 'relative', zIndex: 0 },
        flex: 1,
        '.MuiSkeleton-root': {
          position: 'absolute',
          inset: 0,
          height: 1,
          width: 1,
        },
        '.MuiTypography-root': {
          textAlign: 'center',
          width: 1,
        },

        '.banner__container': {
          height: ['131.5vw', 710, 248, 337, 408],
          position: 'relative',
        },
        ...props.sx,
      }}
    >
      <Box flex={1}>
        {md ? (
          <DesktopBanner {...props} loading={loading} setLoading={setLoading} />
        ) : (
          <MobileBanner {...props} loading={loading} setLoading={setLoading} />
        )}
      </Box>
    </B2BContainer>
  )
}

const MobileBanner = (props: HeroBannerProps) => {
  const { t } = useT()
  return (
    <>
      <Box className={'banner__container'}>
        <Skeleton
          variant='rectangular'
          sx={{ display: props.loading ? 'initial' : 'none' }}
        />
        {props.images.desktop ? (
          <img
            src={props.images.mobile}
            alt=''
            width={'100%'}
            onLoad={() => props.setLoading && props.setLoading(false)}
          />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{ position: 'relative', width: '100%', height: '100%' }}
          />
        )}
      </Box>
      {props.title && (
        <Typography
          variant={'title3'}
          textAlign='center'
          component='p'
          sx={{ transform: 'translateY(-30vw)' }}
        >
          {t(props.title)}
        </Typography>
      )}
    </>
  )
}

const DesktopBanner = (props: HeroBannerProps) => {
  const { t } = useT()
  return (
    <>
      <Box className={'banner__container'}>
        <Skeleton
          variant='rectangular'
          sx={{ display: props.loading ? 'initial' : 'none' }}
        />
        {props.images.desktop ? (
          <img
            src={props.images.desktop}
            alt=''
            width={'100%'}
            onLoad={() => props.setLoading && props.setLoading(false)}
          />
        ) : (
          <Skeleton
            variant='rectangular'
            sx={{ position: 'relative', width: '100%', height: '100%' }}
          />
        )}
      </Box>
      {props.title && (
        <Typography
          variant={'title2'}
          textAlign='center'
          component='p'
          sx={{ mb: 6 }}
        >
          {t(props.title)}
        </Typography>
      )}
    </>
  )
}

export default HeroBanner
