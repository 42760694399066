import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import { FooterNavItemProps, FooterNavListProps } from 'parsroyal/types'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { showMobileNavigation, showModal } from 'store/reducers/uiSlice'

const FooterNavList = (props: FooterNavListProps) => {
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  return (
    <Stack sx={{ gap: 2, py: 2.5, px: 2 }}>
      {props.title && (
        <Typography variant={'bodyBold'}>{t(props.title)}</Typography>
      )}
      <Stack
        sx={{
          gap: 0.5,
          a: {
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
              textDecorationColor: colors.gray[7],
            },
          },
        }}
      >
        {props.navItem?.map((item) => (
          <FooterNavItem {...props} {...item} key={item.id} />
        ))}
      </Stack>
    </Stack>
  )
}

const FooterNavItem = (item: FooterNavItemProps) => {
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const { locale } = useParams()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const global = useAppSelector(getGlobal)
  const dispatch = useAppDispatch()

  const label = (text: string) => {
    return (
      <Typography
        variant='caption'
        sx={{
          fontWeight: dir === 'rtl' ? 500 : 600,
        }}
      >
        {t(text)}
      </Typography>
    )
  }

  return (
    <>
      {
        <NavLink
          to={`/${locale || 'en'}/${item.url}`}
          className={({ isActive, isPending }) =>
            isPending ? 'pending' : isActive ? 'active' : ''
          }
          key={item.url}
        >
          {item.text && label(item.text)}
        </NavLink>
      }
    </>
  )
}

export default FooterNavList
