import {
  IconDefinition,
  faBat,
  faDragon,
  faGamepad,
  faGhost,
  faHatWitch,
  faPawClaws,
  faPlaneProp,
  faRocketLaunch,
  faStarfighter,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'

import { AlienContext } from 'App'
import useT from 'hooks/useTranslation'
import { Game } from 'parsroyal/types'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi, hideMobileNavigation } from 'store/reducers/uiSlice'

// const gameList: GameProps[] = [
//   {
//     label: 'games.crashroyale',
//     gameId: '1001',
//     slug: 'crashroyale',
//     gameplay: 'crashroyale',
//     icon: faRocketLaunch,
//   },
//   {
//     label: 'Crash 3DX',
//     gameId: '1002',
//     slug: 'crash3dx',
//     gameplay: 'crash3dx',
//     icon: faStarfighter,
//   },
//   {
//     label: 'Blood Burst',
//     gameId: '1003',
//     slug: 'bloodburst',
//     gameplay: 'bloodburst',
//     icon: faBat,
//   },
//   {
//     label: 'Crash Ghostly',
//     gameId: '1004',
//     slug: 'crashghostly',
//     gameplay: 'crashghostly',
//     icon: faGhost,
//   },
//   {
//     label: 'Crash Witch',
//     gameId: '1005',
//     slug: 'crashwitch',
//     gameplay: 'crashwitch',
//     icon: faHatWitch,
//   },
//   {
//     label: 'Crash 1917',
//     gameId: '1006',
//     slug: 'crash1917',
//     gameplay: 'crash1917',
//     icon: faPlaneProp,
//   },
//   {
//     label: 'Dragonflare',
//     gameId: '1007',
//     slug: 'dragonflare',
//     gameplay: 'dragonflare',
//     icon: faDragon,
//   },
//   {
//     label: 'Jogo Do Bicho',
//     gameId: '2001',
//     slug: 'jogodobicho',
//     gameplay: 'jogodobicho',
//     icon: faPawClaws,
//   },
// ]

export const Games = () => {
  const { gameList } = useAppSelector(getUi)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm')) // Check for medium (md) screens

  // TODO: Strapify
  return (
    <Stack
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
      }}
    >
      {sm ? (
        <Container fixed>
          <Grid container sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
            {gameList.map((item) => (
              <GridItem {...item} key={item.slug} />
            ))}
          </Grid>
        </Container>
      ) : (
        // For non-md screens, render the grid without Container
        <Grid container sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
          {gameList.map((item) => (
            <GridItem {...item} key={item.slug} />
          ))}
        </Grid>
      )}
    </Stack>
  )
}

const GridItem = (props: Game) => {
  const theme = useTheme()
  const { locale } = useParams()
  const { t } = useT()
  const boxSize = [100, 120, 200]
  const [open, setOpen] = useState(false)
  const global = useAppSelector(getGlobal)
  const alienContext = useContext(AlienContext)
  const dispatch = useAppDispatch()
  let icon: IconDefinition = faGamepad

  const handleDrawerClick = () => {
    dispatch(hideMobileNavigation())
  }

  switch (props.gameId) {
    case '1001':
      icon = faRocketLaunch
      break
    case '1002':
      icon = faStarfighter
      break
    case '1003':
      icon = faBat
      break
    case '1004':
      icon = faGhost
      break
    case '1005':
      icon = faHatWitch
      break
    case '1006':
      icon = faPlaneProp
      break
    case '1007':
      icon = faDragon
      break
    case '2001':
      icon = faPawClaws
      break

    default:
      break
  }

  const content = () => {
    return (
      <IconButton
        sx={{ width: boxSize, height: boxSize, background: colors.gray[4] }}
        onClick={() => setTimeout(() => handleDrawerClick(), 100)}
      >
        <Stack sx={{ gap: 1.5 }}>
          <FontAwesomeIcon icon={icon} />
          <Typography variant='bodySmall' sx={{ fontWeight: 700 }}>
            {t(props.title)}
          </Typography>
        </Stack>
      </IconButton>
    )
  }
  return (
    <Grid
      item
      // xs={4}
      // sm={3}
      sx={{
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',

        p: 0.5,
        '.MuiDivider-root': { borderStyle: 'dashed', borderWidth: '1px' },
        svg: { color: colors.gray[7] },
        a: {
          textDecoration: 'none',
        },
        '.active, a:hover': {
          '.MuiButtonBase-root svg, .MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      }}
    >
      <NavLink
        to={`/${locale || 'en'}/play/${props.slug}`}
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? 'active' : ''
        }
      >
        {content()}
      </NavLink>
    </Grid>
  )
}
