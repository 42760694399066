import { PaymentGatewayName } from '@alienclient/back-front'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import useT from 'hooks/useTranslation'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'

export interface PaymentGatewayProps {
  paymentGateway: PaymentGatewayName
  setPaymentGateway: Dispatch<SetStateAction<PaymentGatewayName>>
}

export const PaymentGatewaySelect = (props: PaymentGatewayProps) => {
  const { paymentGateway, setPaymentGateway } = props
  const alienContext = useContext(AlienContext)
  const { paymentGateways } = useAppSelector(getGlobal)
  const [searchParams, setSearchParams] = useSearchParams()
  const wallet = useAppSelector(getGlobal).player?.wallet
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  const handleChange = (e: SelectChangeEvent) => {
    setPaymentGateway(e.target.value as PaymentGatewayName)
  }

  if (!wallet?.byCurrency) return <></>

  const menuList = () => {
    if (paymentGateways) {
      return Object.keys(paymentGateways).map((item) => {
        if (item === PaymentGatewayName.PW) return <div key={item}></div>

        return (
          <MenuItem
            key={item}
            value={item}
            sx={{
              '*': {
                fontFamily: `${
                  i18n.dir(i18n.language) === 'rtl'
                    ? 'Iranyekan'
                    : '"Nunito Sans"'
                }, sans-serif !important`,
              },
            }}
          >
            <Stack>
              {/* Currency icon to be here */}
              <Typography sx={{ fontSize: 16 }}>
                {t(`payment_gateway.${item}`)}
              </Typography>
              {/* <Typography>{item}</Typography> */}
            </Stack>
          </MenuItem>
        )
      })
    }
  }

  return (
    <Stack sx={{ gap: 2.5, width: 1 }}>
      <FormControl fullWidth variant='filled'>
        <InputLabel
          id='selectPaymentGatewayLabel'
          sx={{
            top: '-2px',
            padding: '4px 10px',
            '*': {
              fontFamily: `${
                i18n.dir(i18n.language) === 'rtl'
                  ? 'Iranyekan'
                  : '"Nunito Sans"'
              }, sans-serif !important`,
            },
            ...(dir === 'rtl'
              ? { right: 0, left: 'initial' }
              : { left: 0, right: 'initial' }),
          }}
        >
          {t('wallet.payment_gateway')}
        </InputLabel>
        <Select
          labelId='selectPaymentGatewayLabel'
          size='small'
          value={paymentGateway}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            marginThreshold: 0,
          }}
          sx={{
            position: 'relative',
            borderRadius: '12px',
            height: '48px',
            padding: '0 6px',
            backgroundColor: colors.gray[2],
            border: `1px solid ${colors.gray[4]} !important`,
            ':hover, :focus': {
              backgroundColor: colors.gray[2],
            },
            '.MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
            '.MuiStack-root': {
              flexDirection: 'row',
              gap: 0.5,
            },
            '.MuiSvgIcon-root':
              dir === 'rtl'
                ? {
                    left: '12px',
                    right: 'initial',
                  }
                : { right: '12px', left: 'initial' },
          }}
          disableUnderline
        >
          {menuList()}
        </Select>
      </FormControl>
    </Stack>
  )
}
