import { PlayerKycVerification } from '@alienclient/back-front'
import { PlayerVerificationStatus } from '@alienclient/back-front/lib/enum/player-verification-status.enum'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import axios from 'axios'
import useT from 'hooks/useTranslation'
import { Interweave } from 'interweave'
import { enqueueSnackbar } from 'notistack'
import UserProfile from 'parsroyal/components/UserProfile/UserProfile'
import { FormEvent, useContext, useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { clearUploadFileUrl, getGlobal } from 'store/reducers/globalSlice'
import { getUi, showToast } from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'

const Verification = () => {
  const { t } = useT()
  const [globalLoading, setGlobalLoading] = useState(false)
  const { locale } = useParams()
  const global = useAppSelector(getGlobal)
  const { verification } = useAppSelector(getUi)
  const playerVerifications = global.player?.kyc?.verifications
  const alienContext = useContext(AlienContext)
  const navigate = useNavigate()

  // If user is logged out, navigate to home page
  useEffect(() => {
    if (!alienContext?.isSocketConnected) {
      navigate(`/${locale}`)
    }
  }, [alienContext?.isSocketConnected])

  let content

  switch (verification?.content) {
    case '<p>Please upload the required documents to verify your identity. Make sure that the documents are clear and legible. Invalid or unreadable documents will be rejected.</p>':
      content = `<p>${t('verification.description')}</p>`
      break
    default:
      content = verification?.content
      break
  }

  return (
    <Stack sx={{ gap: 3 }}>
      <UserProfile />
      <Stack sx={{ color: colors.gray[8] }}>
        <Interweave tagName='div' content={content} />
      </Stack>
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {playerVerifications &&
          playerVerifications.length > 0 &&
          playerVerifications
            .slice() // Create a shallow copy to avoid modifying the original array
            .sort((a, b) => a.order - b.order) // Sort the array based on the 'order' property
            .map(
              (item) =>
                item.required && (
                  <UploadFileToVerify
                    {...item}
                    key={item.uuid}
                    globalLoading={globalLoading}
                    setGlobalLoading={setGlobalLoading}
                  />
                )
            )}
      </Stack>
    </Stack>
  )
}

interface UploadFileToVerifyProps {
  globalLoading: boolean
  setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const UploadFileToVerify = (
  props: PlayerKycVerification & UploadFileToVerifyProps
) => {
  const fileTypes = ['JPEG', 'JPG', 'PNG']
  const mimeTypes = ['application/pdf', 'image/jpeg', 'image/png']
  const [hideChildren, setHideChildren] = useState(false)
  const [loading, setLoading] = useState(false)
  const alienContext = useContext(AlienContext)
  const global = useAppSelector(getGlobal)
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const { t } = useT()

  const [file, setFile] = useState<File>()
  const [previewUrl, setPreviewUrl] = useState<string | undefined>()
  const showTrashIcon =
    props.status === PlayerVerificationStatus.REJECTED ||
    props.status === PlayerVerificationStatus.NOT_SUBMITTED

  const handleChange = (file: File) => {
    setFile(file)
    if (file && mimeTypes.includes(file.type)) {
      const reader = new FileReader()

      // Set up an event listener to update the preview when the file reader has finished loading the image
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
      }

      // Read the selected file as a data URL
      reader.readAsDataURL(file)
    } else {
      // If the selected file is not an image, clear the preview
      setPreviewUrl(undefined)
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    props.setGlobalLoading(true)
    try {
      if (file && alienContext?.isSocketConnected) {
        const getUrl = async () => {
          alienContext.client.getUploadUrl(
            { fileExtension: file.type.split('/')[1] },
            { uuid: uuidv4() }
          )
        }
        getUrl()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteFile = () => {
    setFile(undefined)
    setPreviewUrl(undefined)
    dispatch(clearUploadFileUrl())
    setLoading(false)
    props.setGlobalLoading(false)
    if (alienContext?.isSocketConnected) {
      alienContext.client.deleteKycVerificationFile(
        { uuid: props.uuid },
        { uuid: uuidv4() }
      )
    }
  }

  useEffect(() => {
    if (global.uploadFileUrl?.uploadUrl) {
      const uploadFile = async () => {
        if (!loading) {
          return
        }
        if (file && global.uploadFileUrl) {
          try {
            const uploadRes = await axios.put(
              global.uploadFileUrl.uploadUrl,
              file,
              {
                headers: {
                  'Content-Type': file?.type,
                  'x-ms-blob-type': 'BlockBlob',
                },
              }
            )

            if (alienContext?.isSocketConnected) {
              alienContext.client.addKycVerificationFile(
                {
                  fileUrl: global.uploadFileUrl.fileUrl,
                  uuid: props.uuid,
                },
                {
                  uuid: uuidv4(),
                }
              )
            }
            setLoading(false)
            props.setGlobalLoading(false)
          } catch (error) {
            dispatch(
              showToast({
                show: true,
                message: 'Error',
                message2: `${error}`,
                severity: 'error',
              })
            )
            console.error('Server returned an error:', error)
            setLoading(false)
            props.setGlobalLoading(false)
          }
        }
      }

      uploadFile()
    }
  }, [global.uploadFileUrl])

  const children = () => {
    if (!hideChildren) {
      return props.status === PlayerVerificationStatus.NOT_SUBMITTED ? (
        <Stack sx={{ alignItems: 'center', gap: 0.75 }}>
          {file ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='bodyXSmall'
                sx={{
                  fontFamily: `${
                    dir === 'rtl' ? 'Iranyekan Light' : '"Nunito Sans"'
                  }, sans-serif !important`,
                  color: colors.gray[8],
                  maxWidth: '170px', // Set the maximum width
                  overflow: 'hidden', // Hide the overflow content
                  textOverflow: 'ellipsis', // Show ellipsis for overflow
                  whiteSpace: 'nowrap', // Prevent wrapping to the next line
                }}
              >
                {file?.name}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant='subHeadline'>
                {t('verification.upload_file')}
              </Typography>
              {/* <Typography variant="subHeadline">Drag and Drop</Typography> */}
              <Typography variant='bodyBold' sx={{ color: colors.gray[7] }}>
                {fileTypes.join(', ')} (5MB Max)
              </Typography>
            </>
          )}
        </Stack>
      ) : (
        <></>
      )
    } else {
      return null
    }
  }

  let message
  let status
  let color = colors.gray[5]

  switch (props.status) {
    case PlayerVerificationStatus.NOT_SUBMITTED:
      status = t('verification.status.not_submitted')
      message = t('verification.messageForPlayer.not_submitted')
      break
    case PlayerVerificationStatus.PENDING:
      color = colors.secondary[6]
      status = t('verification.status.pending')
      message = t('verification.messageForPlayer.pending')
      break
    case PlayerVerificationStatus.REJECTED:
      color = '#F04438'
      status = t('verification.status.rejected')
      message =
        props.messageForPlayer || t('verification.messageForPlayer.rejected')
      break
    case PlayerVerificationStatus.CONFIRMED:
      color = colors.tertiary[6]
      status = t('verification.status.confirmed')
      message =
        props.messageForPlayer || t('verification.messageForPlayer.confirmed')
      break

    default:
      color = colors.gray[5]
      break
  }

  let titleDocument
  switch (props.title) {
    case 'ID':
      titleDocument = t('verification.var_documents.id_card')
      break
    case 'ID Card':
      titleDocument = t('verification.var_documents.id_card')
      break
    case 'Birth Certificate':
      titleDocument = t('verification.var_documents.birth_certificate')
      break
    case 'Passport':
      titleDocument = t('verification.var_documents.passport')
      break
    case 'Document':
      titleDocument = t('verification.var_documents.document')
      break

    default:
      titleDocument = props.title
  }

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <Typography
        variant='bodyBold'
        sx={{
          width: '100%',
          fontFamily: `${
            dir === 'rtl' ? 'Iranyekan Light' : '"Nunito Sans"'
          }, sans-serif !important`,
          fontWeight: dir === 'rtl' ? 500 : 600,
          textAlign: `${dir === 'rtl' ? 'right' : 'left'}`,
          color: colors.gray[8],
        }}
      >
        {titleDocument}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: 260,
          padding: '40px 30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: '16px',
          border: `2px dashed ${color}`,
          textAlign: 'center',
          label: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.6,
            },
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack sx={{ gap: file ? 1 : 2 }}>
            {(previewUrl || props.fileUrl) && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '165px',
                  height: '118px',
                  margin: '0 auto',
                  padding: '10px',
                  position: 'relative',
                  borderRadius: '12px',
                  border: '1px solid #5C5474',
                  background: '#332C4A',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={props.fileUrl || previewUrl}
                  alt='File Preview'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              </Box>
            )}

            {props.status === PlayerVerificationStatus.NOT_SUBMITTED && (
              <Box sx={{ display: 'flex' }}>
                <FileUploader
                  handleChange={handleChange} // Specify the type of 'file' here
                  name='file'
                  types={fileTypes}
                  children={children()}
                  onTypeError={(file: File) =>
                    enqueueSnackbar('File type not supported', {
                      variant: 'error',
                    })
                  }
                  onSizeError={(file: File) =>
                    enqueueSnackbar('Maximum file size is 5mb', {
                      variant: 'error',
                    })
                  }
                  maxSize={5}
                  onDraggingStateChange={(dragging: boolean) =>
                    setHideChildren(dragging)
                  }
                />
                {file && showTrashIcon && (
                  <IconButton
                    sx={{ fontSize: 16 }}
                    onClick={() => handleDeleteFile()}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                )}
              </Box>
            )}

            {props.status !== PlayerVerificationStatus.NOT_SUBMITTED ? (
              <>
                <Box>
                  <Typography
                    variant='bodyBold'
                    sx={{
                      width: '100%',
                      fontSize: '10px',
                      textAlign: 'left',
                      padding: '5px 10px',
                      color: colors.secondary[12],
                      backgroundColor: color,
                      borderRadius: '40px',
                      mx: 1,
                    }}
                  >
                    {status}
                  </Typography>
                  {showTrashIcon && (
                    <IconButton
                      sx={{ fontSize: 16 }}
                      onClick={() => handleDeleteFile()}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  )}
                </Box>
                <Typography
                  variant='bodyBold'
                  sx={{
                    width: '100%',
                    fontFamily: `${
                      dir === 'rtl' ? 'Iranyekan Light' : '"Nunito Sans"'
                    }, sans-serif !important`,
                    fontSize: '12px',
                    textAlign: 'center',
                    color: `${color}`,
                  }}
                >
                  {message}
                </Typography>
              </>
            ) : (
              <Box>
                <LoadingButton
                  loadingIndicator={t('verification.uploading')}
                  variant='contained'
                  type='submit'
                  loading={loading} // Use loading state for the specific document type
                  disabled={!file || props.globalLoading}
                  sx={{
                    fontFamily: `${
                      dir === 'rtl' ? 'Iranyekan Light' : '"Nunito Sans"'
                    }, sans-serif !important`,
                    minWidth: loading ? `120px` : 'auto',
                    background: `${colors.secondary[4]}`,
                    color: `${colors.secondary[12]}`,
                  }}
                >
                  {t('verification.upload')}
                </LoadingButton>
              </Box>
            )}
          </Stack>
        </form>
      </Box>
    </Box>
  )
}

export default Verification
