import { faAddressCard, faRightLeft } from '@fortawesome/pro-duotone-svg-icons'
import SideDrawer from 'parsroyal/components/SideDrawer/SideDrawer'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideVerificationSideDrawer } from 'store/reducers/uiSlice'
import Verification from './Verification'

const VerificationSideDrawer = () => {
  const { verificationSideDrawer } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  return (
    <SideDrawer
      open={verificationSideDrawer.open}
      title='nav.verification'
      icon={faAddressCard}
      onClose={() => dispatch(hideVerificationSideDrawer())}
    >
      <Verification />
    </SideDrawer>
  )
}

export default VerificationSideDrawer
