import { Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { popupModalApi } from 'apis/strapiApi'
import useT from 'hooks/useTranslation'
import i18n from 'i18n'
import { Interweave } from 'interweave'
import { Popup } from 'parsroyal/types'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hidePopupModal, showPopupModal } from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'
import { ModalComponent } from '.'
import StrapiButton from '../StrapiButton/StrapiButton'

const PopupModal = () => {
  const { t } = useT()
  const alienContext = useContext(AlienContext)
  const theme = useTheme()
  const dir = i18n.dir(i18n.language)
  const { locale } = useParams()
  const dispatch = useAppDispatch()
  const { popupModal, generalConfig } = useAppSelector(getUi)
  const [popup, setPopup] = useState<Popup>()
  const location = useLocation()

  useEffect(() => {
    const getPopup = async () => {
      const data = await popupModalApi(locale)
      if (data) {
        setPopup(data.data)
      }
    }

    if (locale) {
      getPopup()
    }
  }, [locale])

  useEffect(() => {
    if (alienContext?.isSocketConnected) {
      if (popup?.showModal && popupModal.open) dispatch(showPopupModal())
    }
    if (
      !localStorage.getItem('auth') &&
      !location.pathname.includes('funTest') &&
      !location.pathname.includes('logs')
    ) {
      dispatch(showPopupModal())
    }
  }, [popup])

  const handleClose = () => {
    dispatch(hidePopupModal())
    if (alienContext?.isSocketConnected)
      alienContext.client.setUiState(
        { state: JSON.stringify({ hidePopupModal: true }) },
        { uuid: uuidv4() }
      )
  }

  if (!popup || !popup?.showModal) return <></>

  return (
    <ModalComponent
      open={popupModal.open}
      onClose={() => handleClose()}
      height={510}
      width={450}
      showCloseButton
    >
      <Stack
        sx={{
          flexDirection: 'column',
          width: 1,
          alignItems: 'center',
          gap: 1.5,
          p: 1,
          textTransform: 'uppercase',
          textAlign: 'center',
        }}
      >
        <Stack
          sx={{
            maxWidth: 372,
            '.MuiTypography-root': {
              fontFamily: `${
                dir === 'rtl' ? 'Iranyekan Bold' : '"Nunito Sans"'
              }, sans-serif !important`,
            },
          }}
        >
          <Typography
            variant='subHeadline'
            sx={{ fontSize: 20, fontWeight: 900 }}
          >
            {popup.subHeadline}
          </Typography>
          <Typography
            variant='title3'
            sx={{ fontSize: 32, fontWeight: 900 }}
            color={theme.palette.primary.main}
          >
            {popup.headline}
          </Typography>
        </Stack>
        <Stack
          id='popup__content'
          sx={{
            mt: 2.5,
            img: { borderRadius: 3 },
            p: {
              textTransform: 'initial',
              color: colors.gray[9],
              fontWeight: 600,
            },
          }}
        >
          {popup.content ? (
            <Interweave content={popup.content} />
          ) : (
            <Skeleton
              variant='rectangular'
              sx={{ position: 'relative', height: '250px' }}
            />
          )}
        </Stack>
        <Stack
          sx={{
            width: 1,
            'a, button': {
              width: 1,
            },
            '.MuiButton-root': {
              background: `${colors.primary[4]} !important`,
              border: 'none',
              color: 'initial',
              ':hover, :focus': {
                opacity: 0.9,
              },
            },
          }}
        >
          {popup.showButton &&
            popup.button.map((item) => (
              <StrapiButton {...item} key={item.id} fullWidth />
            ))}
        </Stack>
      </Stack>
    </ModalComponent>
  )
}

export default PopupModal
