import React, { useEffect, useState } from 'react'
import PageContent from '../PageContent/PageContent'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'
import { Box, Card, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { comingSoonApi } from 'apis/strapiApi'
import { Interweave } from 'interweave'
import { colors } from '@theme'

const ComingSoon = () => {
  const { generalConfig } = useAppSelector(getUi)
  const [comingSoon, setComingSoon] = useState()
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const getComingSoon = async () => {
      const data = await comingSoonApi(i18n.language)
      if (data) setComingSoon(data.data.content)
    }

    getComingSoon()
  }, [])

  if (!comingSoon) return <></>

  return (
    <PageContent>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          pt: 30,
          color: colors.gray[9],
        }}
      >
        <Interweave content={comingSoon} />
      </Stack>
    </PageContent>
  )
}

export default ComingSoon
