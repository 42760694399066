import { faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons'
import { Box, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material'
import { gameBannerApi } from 'apis/strapiApi'
import PlayDemoButton from 'parsroyal/components/PlayDemoButton/PlayDemoButton'
import Section from 'parsroyal/components/Section/Section'
import { GameBannerType } from 'parsroyal/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const GameBanner = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const { i18n } = useTranslation()
  const { locale } = useParams()
  const dir = i18n.dir(i18n.language)

  const [gameBanner, setGameBanner] = useState<GameBannerType>()

  useEffect(() => {
    const getbannerOne = async () => {
      const data = await gameBannerApi(locale)
      if (data) setGameBanner(data.data)
    }
    getbannerOne()
  }, [i18n.language])

  return (
    <Section title='nav.games' icon={faRocketLaunch}>
      <Stack sx={{ position: 'relative', img: { borderRadius: 3 } }}>
        <Box
          sx={{
            height: [1, 694.83, 176.78, 257],
            '.MuiSkeleton-root': { height: 1, borderRadius: 4 },
          }}
        >
          {gameBanner ? (
            <img
              src={
                md
                  ? gameBanner.img?.bannerImg?.url
                  : gameBanner.img?.bannerImgMobile?.url
              }
              alt='/'
            />
          ) : (
            <Skeleton variant='rectangular' />
          )}
        </Box>
        <Stack
          sx={{
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center',
            gap: 1.5,
            textAlign: 'center',
            bottom: { xs: 32, md: 8, lg: 24 },
            width: { xs: 1, md: 'initial' },
            ...(gameBanner?.button?.position === 'LEFT' && {
              left: ['initial', 'initial', 20, 73, 83],
            }),
            ...(gameBanner?.button?.position === 'RIGHT' && {
              right: ['initial', '1vw', 20, 73, 83],
            }),
          }}
        >
          {gameBanner?.button?.game && (
            <PlayDemoButton
              variant='contained'
              size='large'
              {...gameBanner?.button.game}
            />
          )}
        </Stack>
      </Stack>
    </Section>
  )
}

export default GameBanner
