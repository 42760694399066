import telegramLogo from '@assets/logo_telegram.png'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { connectSocket } from '@shared'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { authTelegramApi } from 'apis/authApi'
import { getAffiliateCookies } from 'helpers/helpers'
import useT from 'hooks/useTranslation'
import { useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getUi,
  hideLoginModal,
  hideMobileNavigation,
  showToast,
} from 'store/reducers/uiSlice'

interface ScriptTagProps {
  src: string
  async?: boolean
}

export const TelegramLoginButton = ({ isTest }: { isTest?: boolean }) => {
  const dispatch = useAppDispatch()
  const alienContext = useContext(AlienContext)
  const { generalConfig } = useAppSelector(getUi)
  const scriptProps: ScriptTagProps = {
    src: 'https://telegram.org/js/telegram-widget.js?22',
    async: true,
  }
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useT()
  const { affId, channelId, refId } = getAffiliateCookies()

  useEffect(() => {
    if (alienContext?.isSocketConnected) setIsLoading(false)
  }, [alienContext?.isSocketConnected])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = scriptProps.src
    script.async = scriptProps.async ?? true

    // Remove any existing scripts with the same src attribute
    const existingScripts = document.querySelectorAll(
      `script[src="${scriptProps.src}"]`
    )
    existingScripts.forEach((el) => {
      if (el.parentNode) {
        el.parentNode.removeChild(el)
      }
    })

    // Append the new script element to the document body
    document.body.appendChild(script)

    // Clean up by removing the script when the component unmounts
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script)
      }
    }
  }, [scriptProps])

  if (!generalConfig.telegramBotId) return <></>

  // call telegram auth using bot id
  const callTelegramAuth = () => {
    setIsLoading(true)
    window.Telegram.Login.auth(
      { bot_id: generalConfig.telegramBotId, request_access: true },
      (data: any) => {
        if (!data) {
          // authorization failed
        }

        const getAuth = async () => {
          try {
            const resData = await authTelegramApi(
              `${process.env.REACT_APP_SERVER}auth/telegram`,
              {
                ...(affId !== null ? { affId } : {}),
                ...(refId !== null ? { refId } : {}),
                ...(channelId !== null ? { channelId } : {}),
                ...(isTest ? { isTest } : {}),
                ...data,
              }
            )
            if (resData?.group) localStorage.setItem('group', resData?.group)
            if (resData?.token && !alienContext?.isSocketConnected) {
              connectSocket(resData)
              dispatch(
                showToast({
                  show: true,
                  message: 'user.login_success',
                  severity: 'success',
                })
              )
              dispatch(hideLoginModal())
              dispatch(hideMobileNavigation())
            }
          } catch (error) {
            console.error('error::', error)
          }
        }

        getAuth()
      }
    )
    setInterval(() => {
      setIsLoading(false)
    }, 5000)
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: 220,
          height: 48,
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.8,
          },
          display: isLoading ? 'none' : 'block',
        }}
      >
        <Box
          sx={{
            opacity: 0.001,
            position: 'absolute',
            inset: 0,
            width: 220,
            height: 48,
            cursor: 'pointer',
          }}
        >
          <script
            data-telegram-login={generalConfig.telegramBotId}
            // data-size="large"
            // data-request-access="write"
            // data-radius="12"
          ></script>
        </Box>
        <Button
          sx={{
            border: `2px solid ${colors.gray[4]}`,
            color: colors.gray[12],
            fontWeight: 600,
            width: 220,
            height: 48,
            '&:hover,&:focus': {
              background: 'none',
              border: `2px solid ${colors.gray[4]}`,
            },
          }}
          onClick={() => callTelegramAuth()}
          color='secondary'
          variant='outlined'
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              '& > *:not(:last-child)': {
                marginInlineEnd: 1,
              },
              img: { width: 24 },
            }}
          >
            <img src={telegramLogo} alt='' />
            <span>{t('user.sign_in_telegram')}</span>
          </Stack>
        </Button>
      </Box>
      <Box
        sx={{
          height: 48,
          display: isLoading ? 'flex' : 'none',
          alignItems: 'center',
        }}
      >
        <CircularProgress color='primary' size={28} />
      </Box>
    </>
  )
}
