import { faPaperPlaneTop } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import useT from 'hooks/useTranslation'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const LiveSupport = () => {
  return (
    <>
      <Stack
        sx={{
          flex: 1,
          flexDirection: 'column',
          width: 1,
          height: 1,
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <Stack sx={{ alignItems: 'center', gap: 0.75 }}>
          <Avatar src='/assets/jpg/avatar_support.jpg' />
          <Typography
            variant='bodySmall'
            sx={{ fontSize: 12, fontWeight: 600 }}
          >
            Support #1078
          </Typography>
        </Stack>
        <ChatBox />
      </Stack>
    </>
  )
}

const ChatBox = () => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const dir = i18n.dir(i18n.language)
  const [input, setInput] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const stackRef = useRef<HTMLDivElement | null>(null)
  const [messageList, setMessageList] = useState<MessageProps[]>([])
  const [chatBoxHeight, setChatBoxHeight] = useState('100%')
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const alienContext = useContext(AlienContext)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (input) {
      setMessageList((prev) => [...prev, { text: input, isSender: true }])
    }
    setInput('')
  }

  useEffect(() => {}, [isFocused])

  useEffect(() => {
    if (stackRef.current) {
      stackRef.current.scrollTop = stackRef.current.scrollHeight
    }
  }, [messageList, isFocused])

  return (
    <Stack
      sx={{
        justifyContent: 'flex-end',
        background: colors.gray[4],
        borderRadius: 2,
        width: 1,
        minHeight: 'auto',
        height: 1,
        gap: 1.5,
        p: 1.5,
      }}
    >
      <Stack
        ref={stackRef}
        sx={{
          height: chatBoxHeight,
          maxHeight: 400,
          overflow: 'scroll',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isFocused && isMobile ? 'flex-end' : 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            gap: 1.25,
            height: 'auto',
          }}
        >
          <Message isSender text='live_support.msg1' />
          <Message text='live_support.msg2' />
          {messageList.map((item, index) => (
            <Message {...item} key={index} />
          ))}
        </Stack>
      </Stack>
      <form onSubmit={handleSubmit}>
        <TextField
          sx={{
            width: 1,
            input: { fontSize: 16, p: 1, px: 1.5, fontWeight: 500 },
            height: 40,
            mt: 'auto',
            '.MuiInputBase-root': { p: 0, paddingInlineEnd: 1.2 },
          }}
          value={input}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => setInput(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{
                  fontSize: 16,
                  p: 0.75,
                  borderRadius: 0.5,
                  ...(dir === 'rtl' && { rotate: '180deg' }),
                }}
                type='submit'
              >
                <FontAwesomeIcon icon={faPaperPlaneTop} />
              </IconButton>
            ),
          }}
          autoComplete='off'
        />
      </form>
    </Stack>
  )
}

interface MessageProps {
  isSender?: boolean
  text: string
}

const Message = (props: MessageProps) => {
  const { t } = useT()
  return (
    <Box
      sx={{
        marginInlineStart: props.isSender ? 'auto' : 'initial',
        width: 'fit-content',
        display: 'flex',
        py: 1.5,
        px: 2,
        borderRadius: 3,
        background: props.isSender ? colors.gray[5] : colors.gray[6],
        color: colors.gray[12],
      }}
    >
      {t(props.text)}
    </Box>
  )
}
