import { showReloadGameModal } from 'store/reducers/uiSlice'
import store from 'store/store'

export const messagesListener = () => {
  window.addEventListener('message', (event) => {
    if (event.origin === 'https://aliengamescdn.imoon.com') {
      switch (event.data) {
        case 'closeSession':
          console.log('closeSession ', event)
          break

        // case 'reloadRequest':
        //   const iframe = document.getElementById(
        //     'gameIframe'
        //   ) as HTMLIFrameElement
        //   const src = iframe?.src
        //   iframe.src = src
        //   store.dispatch(removeActiveGame())
        //   break

        // Event is received only when game is IDLE
        case 'reload':
          store.dispatch(showReloadGameModal())
          break
        default:
          console.log('received message:', event.data)
          break
      }
    }
  })
}
