import { faClipboard, faCopy } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Chip,
  IconButton,
  Stack,
  StackProps,
  Typography,
} from '@mui/material'
import { colors } from '@theme'
import { formatCurrencyAmount } from 'helpers/helpers'
import i18n from 'i18n'
import { useState } from 'react'

interface DataItemProps {
  label: string
  value: string | number | JSX.Element
  variant?: 'caption' | 'highlight' | 'chip'
  chipColor?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  showCopyIcon?: boolean
  showValue?: boolean
}

export const DataItem = (props: DataItemProps & StackProps) => {
  const { showValue = true } = props
  const dir = i18n.dir(i18n.language)
  const [isCopied, setIsCopied] = useState(false)

  return (
    <Stack
      sx={{
        width: 1,
        gap: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '.MuiTypography-bodySmall, .MuiTypography-caption': {
          color: colors.gray[8],
        },

        ...props.sx,
      }}
    >
      <Typography variant='bodySmall'>{props.label}</Typography>
      {props.variant === 'chip' ? (
        <Chip
          label={props.value}
          color={props.chipColor}
          sx={{ ...props.sx }}
        />
      ) : (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {showValue && (
            <Typography
              variant='bodySmallBold'
              sx={{
                ...(props.variant === 'highlight' && {
                  fontSize: 12,
                  fontWeight: 600,
                  color: colors.secondary[5],
                }),
                ...(props.variant === 'caption' && {
                  fontSize: 12,
                  fontWeight: 600,
                  color: colors.gray[8],
                }),
                textAlign: dir === 'rtl' ? 'left' : 'right',
                lineBreak: 'auto',
                ...(!props.value.toString().includes(' ') && {
                  wordBreak: 'break-all',
                }),
              }}
            >
              {props.value}
            </Typography>
          )}
          {props.showCopyIcon && (
            <IconButton
              sx={{
                paddingInlineEnd: 0,
                ':hover, :focus': { background: 'none' },
              }}
              onClick={() => {
                setIsCopied(true)
                navigator.clipboard.writeText(`${props.value}`)

                setTimeout(() => {
                  setIsCopied(false)
                }, 1000)
              }}
            >
              <FontAwesomeIcon
                icon={isCopied ? faClipboard : faCopy}
                size='2xs'
                color={colors.primary[4]}
                fixedWidth
              />
            </IconButton>
          )}
        </Box>
      )}
    </Stack>
  )
}
