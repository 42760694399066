import { Box, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material'
import UserDrawer from 'parsroyal/components/Drawer'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'
function Logo() {
  const [userDrawer, setGlobalDrawer] = useState<boolean>(false)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const { brand } = useAppSelector(getUi).generalConfig
  const { locale } = useParams()
  const isLogoSvg = brand?.logo?.url.toLowerCase().endsWith('.svg')

  // if (!brand) return <></>

  return (
    <Stack
      direction={'row'}
      sx={{
        cursor: 'pointer',
        justifyContent: 'start',
        alignItems: 'center',
        gap: ['5px', '10px'],
        img: { maxHeight: 45 },
      }}
    >
      <Link to={`/${locale}`}>
        <Stack>
          {brand?.logo ? (
            <Stack>
              <img
                src={md ? brand?.logo?.url : brand?.logoMobile?.url}
                alt="brand logo"
              />
            </Stack>
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{ width: '100px', height: '45px' }}
            />
          )}
        </Stack>
      </Link>

      {userDrawer && <UserDrawer open={userDrawer} setOpen={setGlobalDrawer} />}
    </Stack>
  )
}

export default Logo
