import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { colors } from '@theme'
import { useTranslation } from 'react-i18next'

interface SelectFormProps {
  label: string
  value: string
  disabled?: boolean
  onChange: (event: SelectChangeEvent<string>) => void
  children: React.ReactNode
}

export const SelectForm: React.FC<SelectFormProps> = ({
  label,
  value,
  disabled,
  onChange,
  children,
}) => {
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  return (
    <FormControl fullWidth variant="filled" sx={{ flex: 1 }}>
      <InputLabel
        id="selectYourWalletLabel"
        sx={{
          textAlign: 'right',
          top: '-2px',
          padding: '4px 8px',
          '*': {
            fontFamily: `${
              i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
            }, sans-serif !important`,
          },
          ...(dir === 'rtl' ? { right: -20 } : { left: 0, right: 'initial' }),
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="selectYourWalletLabel"
        disabled={disabled}
        size="small"
        value={value}
        onChange={onChange}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          marginThreshold: 0,
        }}
        sx={{
          position: 'relative',
          borderRadius: '12px',
          height: '48px',
          padding: '0 8px',
          backgroundColor: colors.gray[2],
          border: `1px solid ${colors.gray[4]} !important`,
          ':hover, :focus': {
            backgroundColor: colors.gray[2],
          },
          '&.Mui-disabled': {
            backgroundColor: colors.gray[2],
            input: {
              color: colors.gray[12],
              ':disabled': { color: colors.gray[12] },
            },
          },
          '.MuiFilledInput-input': {
            WebkitTextFillColor: colors.gray[12],
          },
          '&.Mui-focused': {
            backgroundColor: colors.gray[2],
          },

          '.MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
          '.MuiStack-root': {
            flexDirection: 'row',
            gap: 0.5,
          },
          '.MuiSvgIcon-root':
            dir === 'rtl'
              ? {
                  left: '0px',
                  right: 'initial',
                }
              : { right: '12px', left: 'initial' },
        }}
        disableUnderline
      >
        {children}
      </Select>
    </FormControl>
  )
}
