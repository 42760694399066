import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import useT from 'hooks/useTranslation'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

const BannerTwo = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const { locale } = useParams()
  const { t } = useT()

  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Stack>
        {/* <img
          src={`${cdnUrlB2c}home/tournament/banner${
            md ? '.jpg' : '_mobile.jpg'
          }`}
          alt=''
        /> */}
      </Stack>
      <Stack
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',
          gap: 2,
        }}
      >
        {/* <img src={`${cdnUrlB2c}home/tournament/title.jpg`} alt='' /> */}
        <Stack>
          <Typography variant='title1'>$100,000</Typography>
          <Typography variant='body'>
            {t('home.banner_two.headline')}
          </Typography>
        </Stack>
        <NavLink to={`/${locale}/tournament`}>
          <Button variant='contained' color='secondary'>
            {t('general.description')}
          </Button>
        </NavLink>
      </Stack>
    </Stack>
  )
}

export default BannerTwo
