import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const authTelegramApi = async (url: string, authData: any) => {
  const data = JSON.stringify(authData)

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    // url: `${process.env.REACT_APP_SERVER}auth/telegram`,
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const authGoogleApi = async (url: string, authData: any) => {
  const data = JSON.stringify(authData)

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url,
    headers: {
      'Content-Type': 'application/json',
      'google-jwt': authData.googleJwt,
    },
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
