import { faHeadset } from '@fortawesome/pro-duotone-svg-icons'
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  MenuList,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material'
import { navList1, navList2 } from '@shared/constants'
import { IModalProps } from '@type'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi, showSupportModal } from 'store/reducers/uiSlice'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import NavItem from './NavDrawer/NavItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '@theme'
import { Crisp } from 'crisp-sdk-web'
import { AlienContext } from 'App'
import usePlayerCrispData from 'hooks/usePlayerCrispData'

const UserDrawer: FC<IModalProps> = ({ open, setOpen }) => {
  const theme = useTheme()
  const global = useAppSelector(getGlobal)
  const isLoggedIn = global.isLoggedIn
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const { generalConfig } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const alienContext = useContext(AlienContext)
  const activeWallet =
    global.player?.wallet.byCurrency[global.player?.activeCurrency]
  const playerCrispData = usePlayerCrispData()

  const brand = useAppSelector(getUi).generalConfig.brand

  // Create custom hook
  const handleSupportClick = () => {
    if (generalConfig.showCustomSupportModal) {
      dispatch(showSupportModal())
    } else {
      if (generalConfig.crispWebsiteId)
        if (Crisp.chat.isChatOpened()) {
          Crisp.chat.close()
          Crisp.chat.hide()
        } else {
          if (alienContext?.isSocketConnected) {
            Crisp.session.setData(playerCrispData)
          }
          Crisp.chat.open()
          Crisp.chat.show()
        }
    }
    setOpen(false)
  }

  return (
    <Drawer
      dir={dir}
      anchor={dir === 'rtl' ? 'right' : 'left'}
      open={open}
      onClose={() => setOpen(!open)}
      sx={{
        '*': {
          fontFamily: `${
            i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
          }, sans-serif !important`,
        },
        '.MuiDrawer-paper': {
          borderRadius:
            dir === 'rtl'
              ? ' 16px 0px 0px 16px !important'
              : '0px 16px 16px 0px !important',
        },
      }}
    >
      <Stack
        sx={{
          overflow: 'hidden',
          width: 200,
          borderRadius: '12px',
          py: 2.5,
          height: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          sx={{
            width: 97,
            height: 30,
          }}
        >
          {brand ? (
            <img src={brand?.logo?.url} />
          ) : (
            <Skeleton variant='rectangular' sx={{ width: 1, height: 1 }} />
          )}
        </Stack>

        <Stack
          sx={{
            px: 2,
            mt: 7,
            height: 1,
            '.MuiDivider-root': { borderStyle: 'dashed', borderWidth: '1px' },
          }}
        >
          {/* Menu */}
          <MenuList>
            <Stack sx={{ p: 1.5, py: 2.5, gap: 1.5, flex: 1 }}>
              {navList1.map((item) => (
                <NavItem {...item} key={item.href} showLabel />
              ))}
              {isLoggedIn && (
                <>
                  <Divider />
                  {navList2.map((item) =>
                    item.label === 'nav.verification' ? (
                      global.player?.kyc?.verifications &&
                      global.player?.kyc?.verifications?.length > 0 && (
                        <NavItem {...item} key={item.href} showLabel />
                      )
                    ) : (
                      <NavItem {...item} key={item.href} showLabel />
                    )
                  )}
                  <Divider />
                </>
              )}
            </Stack>
          </MenuList>
          <Stack sx={{ minWidth: '155px', gap: 2, mt: 'auto', width: 1 }}>
            <LanguageSelect showLabel />
            <IconButton
              onClick={() => {
                handleSupportClick()
              }}
              sx={{
                svg: { color: colors.gray[9] },
              }}
            >
              <FontAwesomeIcon icon={faHeadset} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default UserDrawer
