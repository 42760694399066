import {
  faAddressCard,
  faBell,
  faCoins,
  faHandHoldingDollar,
  faHouse,
  faMoneySimpleFromBracket,
  faRightLeft,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Container,
  Grid,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import { NavItemProps } from 'parsroyal/types'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import {
  hideMobileNavigation,
  showBetsSideDrawer,
  showMobileNavigation,
  showNotificationSideDrawer,
  showTransactionSideDrawer,
  showVerificationSideDrawer,
  showWalletModal,
} from 'store/reducers/uiSlice'
import { NavIndex } from '@shared/constants'
import useT from 'hooks/useTranslation'

export const More = () => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const global = useAppSelector(getGlobal)

  const moreNavList: NavItemProps[] = [
    {
      type: 'route',
      icon: faHouse,
      label: 'nav.home',
      href: '',
    },
    {
      type: 'action',
      action: () => {
        dispatch(showMobileNavigation({ index: NavIndex.Wallet, open: true }))
      },
      icon: faMoneySimpleFromBracket,
      label: 'nav.deposit',
      queryParams: { key: 'wallet', value: 'deposit' },
      isProtected: true,
    },
    {
      type: 'action',
      action: () => {
        dispatch(showMobileNavigation({ index: NavIndex.Wallet, open: true }))
      },
      icon: faHandHoldingDollar,
      label: 'nav.withdraw',
      queryParams: { key: 'wallet', value: 'withdraw' },
      isProtected: true,
    },
    {
      type: 'action',
      action: () => {
        dispatch(showTransactionSideDrawer())
      },
      icon: faRightLeft,
      label: 'nav.transactions',
      href: 'transactions',

      isProtected: true,
    },
    {
      type: 'action',
      action: () => {
        dispatch(showBetsSideDrawer())
      },
      icon: faCoins,
      label: 'nav.my_bets',
      href: 'bets',
      isProtected: true,
    },
    {
      type: 'action',
      action: () => {
        dispatch(showNotificationSideDrawer())
      },
      icon: faBell,
      label: 'nav.notifications',
      href: 'notifications',
      isProtected: true,
    },
    {
      type: 'action',
      action: () => {
        dispatch(showVerificationSideDrawer())
      },
      icon: faAddressCard,
      label: 'nav.verification',
      href: 'verification',
      isProtected: true,
    },
    // {
    //   type: 'route',
    //   icon: faAddressCard,
    //   label: 'nav.verification',
    //   href: 'verification',
    //   isProtected: true,
    // },
  ]

  return (
    <Stack
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        height: 1,
      }}
    >
      {sm ? (
        <Container fixed>
          <Grid container sx={{ justifyContent: 'center' }}>
            {moreNavList.map((item) =>
              item.label === 'nav.verification' ? (
                global.player?.kyc?.verifications &&
                global.player?.kyc?.verifications?.length > 0 && (
                  <GridItem {...item} key={item.label} />
                )
              ) : (
                <GridItem {...item} key={item.label} />
              )
            )}
          </Grid>
        </Container>
      ) : (
        <Grid container sx={{ justifyContent: 'center' }}>
          {moreNavList.map((item) =>
            item.label === 'nav.verification' ? (
              global.player?.kyc?.verifications &&
              global.player?.kyc?.verifications?.length > 0 && (
                <GridItem {...item} key={item.label} />
              )
            ) : (
              <GridItem {...item} key={item.label} />
            )
          )}
        </Grid>
      )}

      <Box width={170}>
        <LanguageSelect showLabel />
      </Box>
    </Stack>
  )
}

const GridItem = (props: NavItemProps) => {
  const global = useAppSelector(getGlobal)
  const { t } = useT()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { locale } = useParams()

  const handleClick = () => {
    props.action?.()
    if (props.queryParams) {
      searchParams.set(props.queryParams.key, props.queryParams.value)
      setSearchParams(searchParams)
    }
  }

  if (props.isProtected && !global.player?.playerId) return <></>

  return (
    <Grid
      item
      // xs={4}
      // sm={3}
      sx={{
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        p: 0.5,
        '.MuiDivider-root': { borderStyle: 'dashed', borderWidth: '1px' },
        svg: { color: colors.gray[7] },
        a: {
          textDecoration: 'none',
        },
        '.active, a:hover': {
          '.MuiButtonBase-root svg, .MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      }}
    >
      {props.type === 'action' ? (
        <GridItemButton onClick={() => handleClick()}>
          <Stack sx={{ gap: 1.5 }}>
            <FontAwesomeIcon icon={props.icon} />
            <Typography variant='bodySmall' sx={{ fontWeight: 700 }}>
              {t(props.label)}
            </Typography>
          </Stack>
        </GridItemButton>
      ) : (
        <NavLink
          to={`/${locale || 'en'}/${props.href}`}
          className={({ isActive, isPending }) =>
            isPending ? 'pending' : isActive ? 'active' : ''
          }
        >
          <GridItemButton onClick={() => dispatch(hideMobileNavigation())}>
            <Stack sx={{ gap: 1.5 }}>
              <FontAwesomeIcon icon={props.icon} />
              <Typography variant='bodySmall' sx={{ fontWeight: 700 }}>
                {t(props.label)}
              </Typography>
            </Stack>
          </GridItemButton>
        </NavLink>
      )}
    </Grid>
  )
}

const GridItemButton = (props: IconButtonProps) => {
  const boxSize = [100, 130, 200]
  return (
    <IconButton
      sx={{ width: boxSize, height: boxSize, background: colors.gray[4] }}
      {...props}
    >
      {props.children}
    </IconButton>
  )
}
