import {
  faArrowFromLeft,
  faArrowFromRight,
  faHeadset,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Drawer, IconButton, Stack, useTheme } from '@mui/material'
import { navDrawerWidth, navList1, navList2 } from '@shared/constants'
import { colors } from '@theme'
import LanguageSelect from 'parsroyal/components/LanguageSelect/LanguageSelect'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import UserDrawer from '..'
import NavItem from './NavItem'
import { getUi, showSupportModal } from 'store/reducers/uiSlice'
import { Crisp } from 'crisp-sdk-web'
import { AlienContext } from 'App'
import useT from 'hooks/useTranslation'
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons'
import usePlayerCrispData from 'hooks/usePlayerCrispData'

const NavDrawer = () => {
  const [userDrawer, setGlobalDrawer] = useState(false)
  const theme = useTheme()
  const global = useAppSelector(getGlobal)
  const isLoggedIn = global.isLoggedIn
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const { supportModal, generalConfig } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const alienContext = useContext(AlienContext)
  const activeWallet =
    global.player?.wallet.byCurrency[global.player?.activeCurrency]
  const playerCrispData = usePlayerCrispData()

  const handleSupportClick = () => {
    if (generalConfig.showCustomSupportModal) {
      dispatch(showSupportModal())
    } else {
      if (generalConfig.crispWebsiteId) {
        if (Crisp.chat.isChatOpened()) {
          Crisp.chat.close()
        } else {
          if (alienContext?.isSocketConnected) {
            Crisp.session.setData(playerCrispData)
          }
          Crisp.chat.open()
        }
      }
    }
  }

  return (
    <Drawer
      dir={dir}
      variant='permanent'
      open={true}
      sx={{
        // '.MuiPaper-root': {
        //   height: 'auto',
        //   py: 2,
        // },
        a: {
          mx: 'auto',
        },
        width: `${navDrawerWidth}px`,

        '.MuiPaper-root.MuiDrawer-paper':
          dir === 'rtl'
            ? {
                width: 71,
                right: 0,
                left: 'initial',
                borderRadius: '8px 0 0 8px',
              }
            : {
                width: 71,
                left: 0,
                right: 'initial',
                borderRadius: '0 8px 8px 0',
              },
      }}
    >
      <Stack
        sx={{
          p: 1.5,
          py: 2.5,
          gap: 1.5,
          flex: 1,
          '.MuiBox-root': {
            mx: 'auto',
          },
          '.MuiDivider-root': { borderStyle: 'dashed', borderWidth: '1px' },
        }}
      >
        <IconButton
          onClick={() => setGlobalDrawer((prev) => !prev)}
          sx={{ svg: { color: colors.gray[9] }, mb: { md: 9.8 } }}
        >
          <FontAwesomeIcon
            icon={dir === 'rtl' ? faArrowFromRight : faArrowFromLeft}
            size='2xs'
          />
        </IconButton>
        {navList1.map((item) => (
          <NavItem {...item} key={item.href} />
        ))}
        {isLoggedIn && (
          <>
            <Divider />
            {navList2.map((item) =>
              item.label === 'nav.verification' ? (
                global.player?.kyc?.verifications &&
                global.player?.kyc?.verifications?.length > 0 && (
                  <NavItem {...item} key={item.href} />
                )
              ) : (
                <NavItem {...item} key={item.href} />
              )
            )}
            <Divider />
          </>
        )}
        <Stack sx={{ gap: 2, mt: 'auto' }}>
          <LanguageSelect />
          <IconButton
            onClick={() => {
              handleSupportClick()
            }}
            sx={{
              svg: { color: colors.gray[9] },
            }}
          >
            <FontAwesomeIcon icon={faHeadset} />
          </IconButton>
        </Stack>
      </Stack>
      <UserDrawer open={userDrawer} setOpen={setGlobalDrawer} />
    </Drawer>
  )
}

export default NavDrawer
