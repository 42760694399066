import {
  LaunchInfo,
  LaunchInfoStatus,
  OnGetGameLaunchResponseParams,
} from '@alienclient/back-front'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

export const initialState: Partial<
  LaunchInfo & { activeGameId: string; nickname?: string }
> = {}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    initNickname(state, action: PayloadAction<string>) {
      state.nickname = action.payload
    },
    initGame(state, action: PayloadAction<LaunchInfo>) {
      Object.assign(state, action.payload)
    },
    setActiveGame(state, action: PayloadAction<string>) {
      state.activeGameId = action.payload
    },
    removeActiveGame: () => {
      return initialState
    },
    updateGameStatus(state, action: PayloadAction<LaunchInfoStatus>) {
      state.status = action.payload
    },
  },
})

export const { initNickname, initGame, setActiveGame, removeActiveGame } =
  gameSlice.actions
export const getGame = (state: RootState) => state.game
