import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { navList1 } from '@shared/constants'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import { NavItemProps } from 'parsroyal/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getUi,
  resetActiveNavItem,
  setActiveNavItem,
} from 'store/reducers/uiSlice'

const NavItem = (props: NavItemProps) => {
  const { queryParams } = props
  const { activeNavItem, lang } = useAppSelector(getUi)
  const theme = useTheme()
  const { locale } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const currentURL = window.location.href
    const langIndex = currentURL.indexOf(`${lang}`)
    const pathAfterLang = currentURL.slice(langIndex)
    let pathWithoutLang = pathAfterLang.replace(`${lang}`, '')

    if (pathWithoutLang.charAt(0) === '/') {
      pathWithoutLang = pathWithoutLang.substring(1)
    }

    const matchingNavItem = navList1.find((nav) => nav.href === pathWithoutLang)

    if (matchingNavItem) {
      dispatch(setActiveNavItem(matchingNavItem.label))
    }
  }, [window.location.href])

  const handleClick = () => {
    dispatch(setActiveNavItem(props.label))
    if (queryParams) {
      searchParams.set(queryParams.key, queryParams.value)
      // setSearchParams(searchParams)
    }
    if (props.action) props.action()
  }

  const content = () => {
    return !props.showLabel ? (
      <Tooltip
        title={
          <Typography
            variant="caption"
            sx={{
              fontFamily: `${
                dir === 'rtl' ? 'Iranyekan Light' : '"Nunito Sans"'
              }, sans-serif !important`,
              fontWeight: dir === 'rtl' ? 500 : 600,
              fontSize: 14,
            }}
          >
            {t(props.label)}
          </Typography>
        }
        placement="right"
        arrow
      >
        <IconButton
          id={props.href}
          // color='primary'
          onClick={() => handleClick()}
        >
          <FontAwesomeIcon icon={props.icon} size="2xs" fixedWidth />
        </IconButton>
      </Tooltip>
    ) : (
      <Button
        sx={{ p: 1, justifyContent: 'flex-start' }}
        fullWidth
        onClick={() => handleClick()}
      >
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <FontAwesomeIcon icon={props.icon} fixedWidth />
          <Typography variant={dir === 'rtl' ? 'body' : 'bodyBold'}>
            {t(props.label)}
          </Typography>
        </Stack>
      </Button>
    )
  }

  return (
    <Box
      sx={{
        '.MuiDivider-root': { borderStyle: 'dashed', borderWidth: '1px' },
        svg: {
          color:
            activeNavItem === props.label
              ? theme.palette.primary.main
              : colors.gray[9],
        },
        a: {
          textDecoration: 'none',
        },

        '.MuiTypography-root': {
          color:
            activeNavItem === props.label
              ? theme.palette.primary.main
              : colors.gray[12],
        },
      }}
    >
      {/* TODO: Refactor, remove duplicate code for buttons */}
      {props.type === 'action' ? (
        <>{content()}</>
      ) : (
        <NavLink to={`/${locale || 'en'}/${props.href}`}>{content()}</NavLink>
      )}
    </Box>
  )
}

export default NavItem
