import jwtDecode from 'jwt-decode'
import numeral from 'numeral'
import store from 'store/store'

export const formatCurrencyAmount = (
  number: number = 0, // Default to 0 if no number is provided
  currency: string | undefined = undefined,
  acceptedDecimals: number = 0
) => {
  let formatString = '0,00'

  if (acceptedDecimals > 0) {
    // If acceptedDecimals is greater than 0, include the decimal format
    formatString += `.[${'0'.repeat(acceptedDecimals)}]`
  } else if (acceptedDecimals === 0) {
    // If acceptedDecimals is 0, include the optional decimal format
    formatString += '.[00]'
  }

  // Apply formatting based on currency
  if (currency === 'IRT') {
    return numeral(Math.floor(number)).format('0,00')
  } else {
    return numeral(number).format(formatString)
  }
}

export const fetchJSONData = async (url: string) => {
  try {
    const response = await fetch(url) // Fetch the JSON data
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const jsonData = await response.json() // Parse the JSON data
    return jsonData
  } catch (error) {
    console.error('Error fetching JSON:', error)
    throw error
  }
}

export const getTranslation = (key: string) => {
  const array: string[] = key.split('.')

  let translation = store.getState().ui.generalConfig.translations

  if (!translation) return key

  for (const level of array) {
    translation = translation[level]

    // If translation is undefined, return original key
    if (translation === undefined) {
      return key
    }
  }

  if (typeof translation === 'string') return translation
  return key
}

export const postToGameIframe = (message: string, data: any) => {
  const gameIframe = document.getElementById('gameIframe') as HTMLIFrameElement
  if (gameIframe) {
    if (gameIframe.contentWindow)
      gameIframe.contentWindow.postMessage({ event: message, data }, '*')
  }
}

// Set the data in a cookie with an expiration date
export const setCookie = (key: string, value: string, days: number): void => {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + days)
  const cookieValue =
    encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString()
  document.cookie = key + '=' + cookieValue
}

// Get the data from a cookie
export const getCookie = (key: string): string | null => {
  const cookies = document.cookie.split('; ')
  for (const cookie of cookies) {
    const [cookieKey, cookieValue] = cookie.split('=')
    if (cookieKey === key) {
      return decodeURIComponent(cookieValue)
    }
  }
  return null
}

// Remove a cookie by setting its expiration to the past
export const removeCookie = (key: string): void => {
  document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC'
}

export const getAffiliateCookies = () => {
  return {
    affId: getCookie('affId'),
    refId: getCookie('refId'),
    channelId: getCookie('channelId'),
  }
}

type PathMap = Map<any, string>

export const refReplacer = (): ((
  this: any,
  field: string,
  value: any
) => string) => {
  const m: PathMap = new Map()
  const v: PathMap = new Map()
  let init: any = null

  return function (this: any, field: string, value: any): string {
    let p = m.get(this) + (Array.isArray(this) ? `[${field}]` : '.' + field)
    let isComplex = value === Object(value)

    if (isComplex) m.set(value, p)

    let pp = v.get(value) || ''
    let path = p.replace(/undefined\.\.?/, '')
    let val = pp ? `#REF:${pp[0] === '[' ? '$' : '$.'}${pp}` : value

    if (!init) {
      init = value
    } else if (val === init) {
      val = '#REF:$'
    }

    if (!pp && isComplex) v.set(value, path)

    return val
  }
}

interface JWToken {
  aud: string
  exp: number
  iat: number
  role: string[]
  sub: string
}

export const checkJWTValidity = (token: string) => {
  // Move all functions related to cleaning the state to event handler
  const delimiterIndex = token.indexOf('access_token=')
  const jwt = token.substring(delimiterIndex + 1)

  // Decode the JWT
  const decodedToken = jwtDecode<JWToken>(jwt)

  // Extract the expiration time (exp) claim

  const expirationTime = decodedToken.exp

  // Get the current time in seconds

  const currentTime = Math.floor(Date.now() / 1000)

  // Check if the token has expired

  return expirationTime < currentTime
}
