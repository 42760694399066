import {
  Box,
  Button,
  ButtonProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { NavIndex, gameplayBreakpoint } from '@shared/constants'
import { StrapiButtonProps } from 'parsroyal/types'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { showLoginModal, showMobileNavigation } from 'store/reducers/uiSlice'
import PlayDemoButton from '../PlayDemoButton/PlayDemoButton'
import useT from 'hooks/useTranslation'

const StrapiButton = (props: StrapiButtonProps & ButtonProps) => {
  const { locale } = useParams()
  const { i18n } = useTranslation()
  const { t } = useT()
  const navigate = useNavigate()
  const dir = i18n.dir(i18n.language)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))

  const handleMobileClick = (index: number) => {
    if (!breakpoint) {
      dispatch(
        showMobileNavigation({
          open: true,
          index,
        })
      )
    }
  }

  return (
    <Box
      key={props.id}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 1.5,
        ...props.sx,
      }}
    >
      {props.__component === 'common.button' && (
        <NavLink to={`/${i18n.language}${props.url}`}>
          <Button
            variant='outlined'
            className={`${dir === 'rtl' ? 'fa' : 'en'}_bold linkButton`}
            onClick={() => {
              // TODO: Replace after adding ui slice
              document.getElementById('closeModalBtn')?.click()
              navigate(`/${i18n.language}${props.url}`)
            }}
            size={props.size}
          >
            {props.text}
          </Button>
        </NavLink>
      )}
      {props.__component === 'common.play-button' && (
        <PlayDemoButton
          variant='contained'
          value={props.value}
          gameId={props.game?.gameId || ''}
          slug={props.game?.slug || ''}
          fullWidth={props.fullWidth}
          size={props.size}
        />
      )}
      {props.__component === 'common.modal-button' && (
        <>
          {props.modal === 'LOGIN' && (
            <Box>
              <Button
                variant='contained'
                onClick={() => {
                  if (breakpoint) {
                    dispatch(showLoginModal())
                  } else {
                    handleMobileClick(NavIndex.Login)
                  }
                }}
                {...props}
              >
                {props.text && t(props.text)}
              </Button>
            </Box>
          )}
          {props.modal === 'WALLET' && (
            <Box>
              <Button
                variant='contained'
                onClick={() => {
                  if (breakpoint) {
                    dispatch(showLoginModal())
                  } else {
                    handleMobileClick(NavIndex.Wallet)
                  }
                }}
                {...props}
              >
                {props.text && t(props.text)}
              </Button>
            </Box>
          )}
          {props.modal === 'SUPPORT' && (
            <Box>
              <Button
                variant='contained'
                onClick={() => {
                  if (breakpoint) {
                    dispatch(showLoginModal())
                  } else {
                    handleMobileClick(NavIndex.Support)
                  }
                }}
                {...props}
              >
                {props.text && t(props.text)}
              </Button>
            </Box>
          )}
          {props.modal === 'POPUP' && (
            <Box>
              <Button variant='contained' {...props}>
                {props.text && t(props.text)}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default StrapiButton
