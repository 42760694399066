import { Box, Stack, Typography } from '@mui/material'
import { colors } from '@theme'
import useT from 'hooks/useTranslation'
import { Interweave } from 'interweave'
import { GoogleLoginButton } from 'parsroyal/components/GoogleLoginButton/GoogleLoginButton'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'
import { ModalComponent } from '..'
import { TelegramLoginButton } from '../TelegramLoginButton'

export const Login = () => {
  return (
    <Box>
      <Helmet>
        <script
          async
          src='https://telegram.org/js/telegram-widget.js?22'
        ></script>
      </Helmet>
      <Stack
        sx={{
          flexDirection: 'column',
          width: 1,
          alignItems: 'center',
          '& > *:not(:last-child)': {
            mb: 1.25,
          },
        }}
      >
        <Box sx={{ width: 214, height: 241 }}>
          <img src='/assets/png/login_mobile.png' alt='login register banner' />
        </Box>
        <TelegramLoginButton />
        <GoogleLoginButton />
        <HelpInfo />
      </Stack>
    </Box>
  )
}

const HelpInfo = () => {
  const { t } = useT()
  const { loginInfoList } = useAppSelector(getUi)

  const [open, setOpen] = useState(false)

  return (
    <>
      <Stack onClick={() => setOpen(true)} sx={{ mb: 6 }}>
        <Typography
          variant='subtitle2'
          color={colors.secondary[6]}
          sx={{
            textAlign: 'center',
            cursor: 'pointer',
            ':hover,:focus': {
              color: colors.secondary[7],
            },
          }}
        >
          {t('wallet.help')}
        </Typography>
      </Stack>
      <ModalComponent
        open={open}
        onClose={() => setOpen(false)}
        showCloseButton
        title='wallet.help'
        height={700}
      >
        <Stack
          sx={{ gap: 1.5, color: colors.gray[9], img: { borderRadius: 4 } }}
        >
          <Interweave tagName='div' content={loginInfoList[0]?.content} />
        </Stack>
      </ModalComponent>
    </>
  )
}

export default Login
