import {
  PaymentGatewayName,
  PlayerWalletCurrency,
} from '@alienclient/back-front'
import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { formatCurrencyAmount } from 'helpers/helpers'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import {
  getUi,
  showToast,
  showVerificationSideDrawer,
} from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'
import { CurrencySelectProps } from '../../CurrencySelect'
import { PaymentGatewayProps } from '../../PaymentGatewaySelect'

import useT from 'hooks/useTranslation'
import { LoadingButton } from '@mui/lab'
import { BankNameSelect } from '../../JBBankNameSelect'
import i18n from 'i18n'
import { PlayerVerificationStatus } from '@alienclient/back-front/lib/enum/player-verification-status.enum'

interface WithdrawInputProps {
  activeCurrency: string
  activeCurrencyObj: PlayerWalletCurrency
  minAmount: number
  feeAmount: number
}

export const TomanInput = (
  props: WithdrawInputProps & PaymentGatewayProps & CurrencySelectProps
) => {
  const {
    paymentGateway,
    currency,
    activeCurrency,
    activeCurrencyObj,
    feeAmount,
    minAmount,
  } = props
  const { paymentGateways, player } = useAppSelector(getGlobal)
  const { walletConfig, toast } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const alienContext = useContext(AlienContext)
  const [nameOnCard, setNameOnCard] = useState('')
  const [iban, setIban] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [bankName, setBankName] = useState('')
  const { t } = useT()
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAmount, setSelectedAmount] = useState(
    paymentGateways?.JB.withdrawPackages?.[0] || 0
  )
  const ibanIsRequired = paymentGateways?.JB.ibanIsRequired
  const bankNameIsRequired = paymentGateways?.JB.bankNameIsRequired
  const dir = i18n.dir(i18n.language)

  const validateFields = (
    selectedAmount: number,
    nameOnCard: string,
    cardNumber: string,
    iban: string,
    bankName: string,
    ibanIsRequired?: boolean,
    bankNameIsRequired?: boolean
  ) => {
    // Btn enabled by default
    let result = false
    if (ibanIsRequired) {
      // Check if iban is not valid
      if (iban.length !== 24) result = true
    }

    if (bankNameIsRequired) {
      // check if bankname is not valid, result is true (btn will be disabled)
      if (!bankName) result = true
    }

    if (selectedAmount < minAmount) {
      result = true
    }
    if (selectedAmount > activeCurrencyObj.balance) {
      result = true
    }

    if (!nameOnCard) {
      result = true
    }

    if (cardNumber.length !== 16) {
      result = true
    }

    return result
  }

  useEffect(() => {
    const validationResult = validateFields(
      selectedAmount,
      nameOnCard,
      cardNumber,
      iban,
      bankName,
      ibanIsRequired,
      bankNameIsRequired
    )
    setIsDisabled(validationResult)
  }, [selectedAmount, nameOnCard, cardNumber, iban, bankName])

  useEffect(() => {
    if (toast.show) {
      setIsLoading(false)
    }
  }, [toast])

  if (!activeCurrency) return <></>

  const handleWithdraw = () => {
    setIsLoading(true)
    const verifications = player?.kyc?.verifications

    if (!verifications) return // Return early if verifications is null or undefined

    const verificationRequired = verifications.length > 0

    if (verificationRequired) {
      const isPlayerVerified = verifications
        .filter((item) => item.required)
        .every((item) => item.status === PlayerVerificationStatus.CONFIRMED)

      if (!isPlayerVerified) {
        dispatch(
          showToast({
            message: 'wallet.withdraw_verification',
            show: true,
          })
        )
        dispatch(showVerificationSideDrawer())
        return // Return early if not verified
      }
    }

    if (!selectedAmount || !activeCurrency) return // Return early if missing amount or currency

    if (paymentGateway === PaymentGatewayName.JB) {
      alienContext?.client.addJbWithdraw(
        {
          activeCurrency,
          amount: selectedAmount,
          destinationCard: cardNumber,
          nameOnDestinationCard: nameOnCard,
          bankName,
          iban,
        },
        {
          uuid: uuidv4(),
          meta: {
            currency: t(`currencies.${activeCurrency}`, activeCurrency),
            amount: selectedAmount,
          },
        }
      )
    }
  }

  const handleCardNumberChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Remove non-numeric characters
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '')

    // Limit to 16 digits
    const limitedValue = sanitizedValue.slice(0, 16)

    setCardNumber(limitedValue)
    // e.target.value.length <= 16 && setCardNumber(e.target.value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent "e" and "-" from being entered
    if (
      e.key === 'e' ||
      e.key === 'E' ||
      e.key === '.' ||
      e.key === '+' ||
      e.key === '-'
    ) {
      e.preventDefault()
    }

    const allowedKeys = /^[0-9]*$/
    if (!e.key.match(allowedKeys) && e.key !== 'Backspace') {
      e.preventDefault()
    }
  }

  const handleIban = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Remove non-numeric characters
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '')

    // Limit to 16 digits
    const limitedValue = sanitizedValue.slice(0, 24)

    setIban(limitedValue)
    // e.target.value.length <= 16 && setCardNumber(e.target.value)
  }

  return (
    <Stack
      sx={{
        gap: 1.5,
        input: {
          transform: 'translateY(6px)',
        },
      }}
    >
      <Typography
        sx={{
          color: colors.gray[7],
          textAlign: 'center',
          fontSize: 14,
          fontWeight: 600,
        }}
        variant='subtitle2'
      >
        {t('wallet.select_withdraw')}
      </Typography>
      <Grid container dir='ltr'>
        {paymentGateways?.JB.withdrawPackages &&
        paymentGateways?.JB.withdrawPackages.length > 0
          ? paymentGateways?.JB.withdrawPackages.map((number) => (
              <GridItem
                key={number}
                amount={number}
                selectedAmount={selectedAmount}
                setSelectedAmount={setSelectedAmount}
                onClick={() => {}}
                activeCurrencyObj={activeCurrencyObj}
              />
            ))
          : walletConfig.withdrawAmount?.map(
              (item) =>
                item.amount && (
                  <GridItem
                    key={item.id}
                    amount={item.amount}
                    selectedAmount={selectedAmount}
                    setSelectedAmount={setSelectedAmount}
                    onClick={() => {}}
                    activeCurrencyObj={activeCurrencyObj}
                  />
                )
            )}
      </Grid>
      <FormControl>
        <TextField
          dir='ltr'
          type='number'
          value={cardNumber}
          inputProps={{
            maxLength: 16,
            pattern: '[0-9]*',
            inputMode: 'decimal',
          }}
          label={t('wallet.card_no') || ''}
          onChange={handleCardNumberChange}
          onKeyDown={handleKeyPress}
          onFocus={(e) =>
            e.target.addEventListener('wheel', (e) => e.preventDefault(), {
              passive: false,
            })
          }
          sx={{
            '.MuiInputBase-root': {
              border: `1px solid ${colors.gray[4]}`,
              backgroundColor: colors.gray[2],
            },
            fieldset: {
              border: `none`,
            },
            input: { height: 48, py: 0, px: '16px' },
          }}
        />
      </FormControl>
      <FormControl>
        <TextField
          value={nameOnCard}
          label={t('wallet.name_on_card') || ''}
          onChange={(e) => setNameOnCard(e.target.value)}
          sx={{
            transition: 'none',
            '.MuiInputBase-root': {
              border: `1px solid ${colors.gray[4]}`,
              backgroundColor: colors.gray[2],
            },
            fieldset: {
              border: `none`,
            },
            input: { height: 48, py: 0, px: '16px' },
          }}
          InputLabelProps={{
            sx: {
              '&.MuiInputLabel-shrink': {
                transition: 'ease .2s',
                ...(dir === 'rtl'
                  ? { right: 0 }
                  : { left: 0, right: 'initial' }),
              },
              ...(dir === 'rtl'
                ? { right: 24, left: 'initial' }
                : { left: 0, right: 'initial' }),
            },
          }}
        />
      </FormControl>
      {ibanIsRequired && (
        <FormControl>
          <TextField
            dir='ltr'
            value={iban}
            type='number'
            // label={t('wallet.iban')}
            placeholder={t('wallet.iban')}
            InputLabelProps={{}}
            onChange={handleIban}
            onKeyDown={handleKeyPress}
            sx={{
              '.MuiInputBase-root': {
                border: `1px solid ${colors.gray[4]}`,
                backgroundColor: colors.gray[2],
              },
              fieldset: {
                border: `none`,
              },
              input: { height: 48, py: 0, transform: 'translateY(0px)' },
            }}
            inputProps={{
              pattern: '[0-9]*',
              inputMode: 'decimal',
            }}
            InputProps={{
              startAdornment: (
                <Typography
                  variant='bodySmallBold'
                  sx={{
                    fontSize: 16,
                    paddingInlineEnd: 1,
                    paddingLeft: '6px',
                    transform: 'translateY(1px)',
                  }}
                >
                  IR
                </Typography>
              ),
            }}
          />
        </FormControl>
      )}
      {bankNameIsRequired && paymentGateways?.JB.bankNames && (
        <BankNameSelect bankName={bankName} setBankName={setBankName} />
      )}
      <LoadingButton
        disabled={isDisabled}
        loading={isLoading}
        variant='contained'
        onClick={() => handleWithdraw()}
        sx={{
          position: 'sticky',
          bottom: 16,
          zIndex: 10,
        }}
      >
        {t('wallet.withdraw')}
      </LoadingButton>
      <Stack
        sx={{
          gap: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          '.MuiTypography-root': {
            textAlign: 'center',
            color: colors.gray[7],
          },
        }}
      >
        <Typography
          sx={{
            color: colors.gray[7],
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 600,
          }}
          variant='buttonMedium'
        >
          {t('wallet.min_withdraw')} ={' '}
        </Typography>

        <Typography
          variant='buttonMedium'
          sx={{
            color: colors.gray[7],
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 600,
          }}
          component={'span'}
          dir='ltr'
        >
          <span style={{ display: 'inline-block' }}>
            {t(`currencies.${activeCurrency}`, activeCurrency)}
          </span>
          <span> {formatCurrencyAmount(minAmount, activeCurrency)}</span>
        </Typography>
      </Stack>

      <Stack
        sx={{
          gap: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          '.MuiTypography-root': {
            color: colors.gray[7],
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 600,
          },
        }}
      >
        <Typography variant='buttonMedium' sx={{ fontSize: 14 }}>
          {t('wallet.transaction_fee')} =
        </Typography>
        <Typography
          variant='buttonMedium'
          sx={{ fontSize: 14 }}
          component={'span'}
          dir='ltr'
        >
          <span style={{ display: 'inline-block' }}>
            {' '}
            {t(`currencies.${activeCurrency}`, activeCurrency)}
          </span>
          <span> {formatCurrencyAmount(feeAmount, activeCurrency)}</span>
        </Typography>
      </Stack>
    </Stack>
  )
}

interface GridItemProps {
  amount: number
  selectedAmount: number
  setSelectedAmount: Dispatch<SetStateAction<number>>
  onClick: () => void
  activeCurrencyObj: PlayerWalletCurrency
}

const GridItem = (props: GridItemProps) => {
  const { amount, selectedAmount, setSelectedAmount } = props
  const alienContext = useContext(AlienContext)
  const { i18n } = useTranslation()
  const { t } = useT()
  const { locale } = useParams()
  const dir = i18n.dir(i18n.language)

  const handleClick = () => {
    if (amount !== selectedAmount) {
      setSelectedAmount(amount)
      props.onClick()
    }
  }

  return (
    <Grid
      item
      xs={4}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 0.5,
        '.MuiTypography-root': {
          fontSize: 14,
          color: colors.gray[9],
          fontWeight: 700,
        },
        '.MuiButton-root': {
          px: 1,
          py: 0.5,
          border: `1px solid ${colors.gray[4]}`,
          background: colors.gray[2],
          transition: 'opacity 150ms ease-in-out',
          '&:hover': {
            background: colors.gray[2],
            opacity: 0.8,
          },
          '&:disabled': {
            background: colors.gray[4],
            '.MuiTypography-root': {
              color: colors.gray[9],
            },
            ...(props.activeCurrencyObj.balance < amount && {
              background: colors.gray[2],
              '.MuiTypography-root': {
                color: colors.gray[9],
              },
            }),
          },
        },
      }}
    >
      <Button
        fullWidth
        onClick={handleClick}
        disabled={
          props.activeCurrencyObj.balance < amount || selectedAmount === amount
        }
      >
        <Stack>
          <Typography variant={dir === 'rtl' ? 'body' : 'bodyBold'}>
            {formatCurrencyAmount(props.amount, 'IRT')}
          </Typography>
          <Typography variant={dir === 'rtl' ? 'body' : 'bodyBold'}>
            {t('currencies.IRT', 'IRT')}
          </Typography>
        </Stack>
      </Button>
    </Grid>
  )
}
