import { Avatar, Badge, Box, Fade, Menu, Stack, useTheme } from '@mui/material'
import { colors } from '@theme'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserDropDown from './UserDropDown'

function LoggedInUser() {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  const handleClose = () => {
    setAnchorEl(null)
    setDrawerOpen(false)
  }
  return (
    <Stack
      sx={{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        gap: [null, 4],
      }}
    >
      {/* Notifications */}
      <Stack
        position={'relative'}
        onClick={() => setNotificationDrawerOpen(!notificationDrawerOpen)}
        sx={{
          cursor: 'pointer',
        }}
      ></Stack>
      {/* User Avatar */}
      <Box
        sx={{
          cursor: 'pointer',
          '&:hover': { opacity: 0.8 },
        }}
        onClick={() => setDrawerOpen(true)}
      >
        <Badge
          sx={{
            '.MuiBadge-badge': {
              top: '4px',
              right: '2px',
              border: `2px solid ${colors.gray[2]}`,
              borderRadius: '10px',
              width: 21,
              height: 21,
              p: 0.4,
              fontSize: 11,
            },
          }}
          color="error"
          // badgeContent={15}
          max={9}
        >
          <Avatar src="/assets/png/avatar.jpg" alt="userAvatar" />
        </Badge>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={drawerOpen}
        onClose={handleClose}
        transitionDuration={{ appear: 300, enter: 300, exit: 200 }}
        TransitionComponent={Fade}
        sx={{
          '.MuiPaper-root': {
            borderRadius: 3,
            width: 275,
            overflow: 'hidden',
            mt: ['8.5vh', '4vh', '4vh'],
            '& .MuiAvatar-root': {
              // width: 32,
              height: 32,
            },

            '&:before': {
              content: '""',
              top: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: dir === 'rtl' ? 'left' : 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: dir === 'rtl' ? 'left' : 'right',
          vertical: 'top',
        }}
      >
        <UserDropDown open={drawerOpen} setOpen={setDrawerOpen} />
      </Menu>
    </Stack>
  )
}

export default LoggedInUser
