import { Box, Stack, Typography } from '@mui/material'
import { AlienContext } from 'App'
import { useContext, useEffect } from 'react'
import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { v4 as uuidv4 } from 'uuid'
import BetCard from './components/BetCard'
import dayjs from 'dayjs'

const BetsList = () => {
  const alienContext = useContext(AlienContext)
  const betsList = useAppSelector(getGlobal).betsList?.data
  let prevDate: any = null

  const getList = () => {
    if (alienContext?.isSocketConnected)
      alienContext?.client.getBets(
        {},
        {
          uuid: uuidv4(),
          meta: {
            tabId: window.tabId,
          },
        }
      )
  }
  // TODO: Fetch data every 10 seconds
  async function fetchData() {
    try {
      getList()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    // Fetch data immediately on mount
    fetchData()

    // Set up an interval to fetch data every 10 seconds
    const intervalId = setInterval(fetchData, 10000)

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, []) // Empty dependency array ensures this effect runs only on mount and unmount

  useEffect(() => {
    getList()
  }, [alienContext?.isSocketConnected])

  return (
    <Stack sx={{ gap: 2 }}>
      {betsList?.map((item, index) => {
        const currentDate = dayjs(item.placedAt).format('MMM DD, YYYY')
        let isNew = prevDate !== currentDate
        if (isNew) {
          prevDate = currentDate
        }
        return (
          <>
            {isNew && (
              <Typography variant="bodySmallBold" sx={{ margin: '0 auto' }}>
                {dayjs(item.placedAt).format('MMM DD, YYYY')}
              </Typography>
            )}
            <BetCard {...item} key={index} />
          </>
        )
      })}
    </Stack>
  )
}

export default BetsList
