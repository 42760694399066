import { createTheme } from '@mui/material'
import { blue, deepOrange, orange, red } from '@mui/material/colors'
import i18n from 'i18n'

//NextJs
// import { Nunito_Sans } from '@next/font/google'
// const nunito = Nunito_Sans({ weight: ['200', '300', '400', '600', '700', '800', '900'], subsets: ['latin'] });

export const colors = {
  primary: {
    1: '#d04e03',
    2: '#df5900',
    3: '#ee6700',
    4: '#fb8111',
    5: '#f78e2c',
    6: '#f39b49',
    7: '#f9aa62',
    8: '#fdbd75',
    9: '#f9d2a4',
    10: '#fce1c2',
    11: '#feecd8',
    12: '#fff5ea',
  },
  secondary: {
    1: '#002f61',
    2: '#004084',
    3: '#0053ab',
    4: '#005fc3',
    5: '#0c70db',
    6: '#1f84ef',
    7: '#4ca1fb',
    8: '#72b6ff',
    9: '#90c6ff',
    10: '#b9dbff',
    11: '#d6eaff',
    12: '#eaf4ff',
  },
  tertiary: {
    1: '#005415',
    2: '#00661a',
    3: '#047020',
    4: '#07932c',
    5: '#0aad36',
    6: '#0fc943',
    7: '#22e54b',
    8: '#55f570',
    9: '#95ffa6',
    10: '#bfffca',
    11: '#e3ffe8',
    12: '#f1fff4',
  },
  gray: {
    1: '#04020B',
    2: '#110C20',
    3: '#1F1933',
    4: '#332C4A',
    5: '#463E5E',
    6: '#5C5474',
    7: '#746D89',
    8: '#8B859F',
    9: '#A39EB4',
    10: '#BBB7C9',
    11: '#D5D2DF',
    12: '#EDEBF4',
  },
}

export const gradients = {
  2: 'linear-gradient(155.01deg, #201B2D 13.88%, rgba(41, 36, 63, 0.3) 84.1%)',
  5: 'linear-gradient(297.11deg, #2A243A 56.61%, #110C20 98.21%)',
  7: 'linear-gradient(180deg, rgba(42, 36, 58, 0) 0%, #2A243A 100%)',
  14: 'linear-gradient(90deg, #2A243B 0%, #362E4A 105.73%)',
  16: 'linear-gradient(180deg, rgba(30, 133, 241, 0.2) 0%, rgba(0, 0, 0, 0) 55.37%), #1D1929',
}

export const shadows = {
  0: '0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)',
  1: '0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)',
}

// Define your custom breakpoints
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1440,
  },
}

export const theme = createTheme({
  breakpoints,
  color: colors,
  gradients: gradients,
  typography: {
    // fontFamily: '"Nunito Sans", sans-serif', // React fonts
    fontSize: 16,
    fontWeightRegular: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightBold: 900,
    h1: { fontSize: '60px', lineHeight: '72px' },
    h2: { fontSize: '48px', lineHeight: '56px' },
    h3: { fontSize: '34px', lineHeight: '40px' },
    h4: { fontSize: '24px', lineHeight: '32px' },
    h5: { fontSize: '18px', lineHeight: '32px' },
    caption: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: colors.gray[9],
    },
  },
  status: {
    danger: orange[500],
  },

  palette: {
    mode: 'dark',
    primary: {
      main: colors.primary[4],
      light: '#F78E2C',
      dark: colors.primary[3],
      contrastText: '#04020B',
    },
    secondary: {
      main: colors.secondary[5], // Buttons main color
      light: colors.secondary[8],
      dark: colors.secondary[4],
    },

    success: {
      main: colors.tertiary[7],
      contrastText: colors.gray[2],
    },

    error: {
      main: red['A100'],
      contrastText: colors.gray[2],
    },

    warning: {
      main: orange['A200'],
      contrastText: colors.gray[2],
    },

    info: {
      main: blue[300],
      contrastText: colors.gray[2],
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: colors.gray[4],
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          fontSize: 14,
          fontWeight: 700,
          borderRadius: 12,
          padding: '16px 66px',
          textTransform: 'capitalize',
          '&.en_bold': {
            fontFamily: '"Nunito Sans", sans-serif !important',
          },
          '&.fa_bold': {
            fontFamily: 'Iranyekan, sans-serif !important',
          },
        },
        sizeSmall: {
          fontSize: 12,
          padding: '8px 16px',
        },
        sizeMedium: {
          fontSize: 14,
          fontWeight: 700,
          height: 48,
          lineHeight: '135%',
          padding: '8px 16px',
        },
        sizeLarge: {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: '112%',
          padding: '8px 16px',
          height: 48,
          minWidth: 220,
          [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            minWidth: 180, // Adjust the minWidth for sm breakpoint
          },
          [`@media (min-width: ${breakpoints.values.md}px)`]: {
            minWidth: 220, // Adjust the minWidth for md breakpoint
          },
        },

        outlined: {
          borderWidth: 1,
          borderColor: colors.gray[12],
          '&:hover, &:focus': {
            borderWidth: 1,
            borderColor: colors.gray[12],
            background: '#f9f9f911',
          },
        },

        outlinedSecondary: {
          border: `1px solid ${colors.gray[11]}`,
          color: colors.primary[4],

          '&:hover, &:focus': {
            borderWidth: 1,
            border: `1px solid ${colors.gray[11]}`,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&, :hover': { borderRadius: '12px' },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.gray[5]}`,
          borderRadius: '12px !important',
          background: colors.gray[3],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root.MuiDrawer-paper': {
            borderRadius: '0 8px 8px 0',
            border: 'none',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '64px',
          '@media (min-width: 600px)': {
            minHeight: '64px',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: '#1F1933', // gray1
          boxShadow: 'none',
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          // p: '0!important',
          '&.MuiButton-root': {
            // lineHeight: '1rem',
            // minWidth: 220,
          },
          '&.MuiButton-sizeSmall': {
            minWidth: 'initial',
          },
          '&.MuiButton-outlinedWhite': {
            border: `1px solid ${colors.gray[11]}`,
          },
          '&.MuiButton-outlinedGray': {
            border: `1px solid ${colors.gray[4]}`,
            color: colors.gray[7],
          },
          '.MuiButtonBase-root-MuiTab-root': {
            padding: '0!important',
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: 'transparent',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // fontFamily: '"Nunito Sans", sans-serif',
          fontStyle: 'normal',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '130%',
          letterSpacing: '0.36px',
          color: colors.gray[6],
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // fontFamily: '"Nunito Sans", sans-serif',
          fontStyle: 'normal',

          input: {
            color: colors.gray[12],
            fontSize: 16,
            lineHeight: '147%',
            '&::placeholder': {
              color: colors.gray[6],
              opacity: 1,
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: {},
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // fontFamily: '"Nunito Sans", sans-serif',
          fontStyle: 'normal',
          color: colors.gray[12],
        },
      },

      variants: [
        {
          props: { variant: 'title1' },
          style: {
            fontWeight: 700,
            fontSize: '60px',
            lineHeight: '120%',
            letterSpacing: '-0.005em',
          },
        },
        {
          props: { variant: 'title2' },
          style: {
            fontWeight: 700,
            fontSize: '48px',
            lineHeight: '116%',
          },
        },
        {
          props: { variant: 'title3' },
          style: {
            fontWeight: 800,
            fontSize: '34px',
            lineHeight: '117%',
          },
        },
        {
          props: { variant: 'headline' },
          style: {
            fontWeight: 800,
            fontSize: '24px',
            lineHeight: '132%',
            letterSpacing: '0.01em',
            fontFeatureSettings: "'liga' off",
          },
        },
        {
          props: { variant: 'subHeadline' },
          style: {
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '135%',
            letterSpacing: '0.01em',
          },
        },
        {
          props: { variant: 'body' },
          style: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '147%',
          },
        },
        {
          props: { variant: 'bodyBold' },
          style: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '147%',
          },
        },

        {
          props: { variant: 'bodySmall' },
          style: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '116%',
          },
        },
        {
          props: { variant: 'bodyXSmall' },
          style: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '130%',
          },
        },
        {
          props: { variant: 'bodySmallBold' },
          style: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '116%',
          },
        },
        {
          props: { variant: 'bodyMedium' },
          style: {
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '112%',
            letterSpacing: '4%',
          },
        },
        {
          props: { variant: 'bodyLarge' },
          style: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '23px',
            letterSpacing: '4%',
          },
        },
        {
          props: { variant: 'buttonLarge' },
          style: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: '0.03em',
          },
        },
        {
          props: { variant: 'buttonMedium' },
          style: {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '112%',
            letterSpacing: '0.02em',
          },
        },
        {
          props: { variant: 'buttonLarge' },
          style: {
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '130%',
            letterSpacing: '0.04em',
          },
        },
      ],
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: colors.gray[3],
          borderWidth: '2px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: colors.gray[12],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          '&.MuiInputLabel-root': {
            fontWeight: 700,
            color: colors.gray[6],
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, 8px) scale(.75) ',
            fontWeight: 600,
          },
        },
      },
    },
    // MuiTextField: {
    //     styleOverrides: {
    //         root: {
    //             // backgroundColor: '#1D1929'
    //         }
    //     }
    // },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 42,
          height: 42,
          borderRadius: 12,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: colors.gray[3],
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: colors.gray[4] + ' !important',
          padding: '0.8rem 0.6rem',
          '&.Mui-selected': {
            background: colors.gray[6] + ' !important',
          },
        },
      },
    },
  },
})

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true
    title2: true
    title3: true
    headline: true
    subHeadline: true
    body: true
    bodyBold: true

    bodySmall: true
    bodyXSmall: true
    bodySmallBold: true
    bodyMedium: true
    bodyLarge: true
    buttonLarge: true
    buttonMedium: true
    buttonSmall: true
  }
}

declare module '@mui/material/styles/createPalette' {}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
    white: true
    gray: true
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
    color: {
      gray: {}
      primary: {}
      secondary: {}
      tertiary: {}
    }
    gradients: {}
  }

  interface ThemeOptions {
    status?: {
      danger?: string
    }
    color: {
      gray: {}
      primary: {}
      secondary: {}
      tertiary: {}
    }
    gradients: {}
  }
}
