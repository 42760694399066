import { faRectangleXmark } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Paper,
  Stack,
} from '@mui/material'
import { NavIndex, mobileNavList } from '@shared/constants'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { Crisp } from 'crisp-sdk-web'
import useT from 'hooks/useTranslation'
import { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import {
  getUi,
  hideMobileNavigation,
  showMobileNavigation,
} from 'store/reducers/uiSlice'
import { LiveSupport } from '../Modals/LiveSupport'
import Login from '../Modals/Login/Login'
import { Wallet } from '../Modals/Wallet'
import { LiveTournamentStatus } from '../Tournament/LiveTournamentStatus'
import { Games } from './Games'
import { MobileNavItem } from './MobileNavList'
import { More } from './More'
import usePlayerCrispData from 'hooks/usePlayerCrispData'
import TournamentHistory from '../Tournament/TournamentHistory'

// TODO: use redux or context to manage mobile bottom nav state
const MobileBottomNav = () => {
  const pathname = useLocation().pathname
  const { i18n } = useTranslation()
  const { t } = useT()
  const global = useAppSelector(getGlobal)
  const mobileNavigation = useAppSelector(getUi).mobileNavigation
  const isLoggedIn = global.isLoggedIn
  const { generalConfig } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const alienContext = useContext(AlienContext)
  const activeWallet =
    global.player?.wallet.byCurrency[global.player?.activeCurrency]
  const playerCrispData = usePlayerCrispData()

  const handleClose = () => {
    dispatch(hideMobileNavigation())
  }

  const handleResize = () => {
    const userAgent = navigator.userAgent
    let isPhone
    if (
      /Android/i.test(userAgent) ||
      /iPhone|iPad|iPod/i.test(userAgent) ||
      /Windows Phone/i.test(userAgent)
    ) {
      isPhone = true
    } else {
      isPhone = false
    }

    if (!isPhone) handleClose()
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleSupportClick = () => {
    if (generalConfig.showCustomSupportModal) {
      dispatch(showMobileNavigation({ index: NavIndex.Support, open: true }))
    } else {
      if (generalConfig.crispWebsiteId) {
        if (Crisp.chat.isChatOpened()) {
          Crisp.chat.close()
          Crisp.chat.hide()
        } else {
          if (alienContext?.isSocketConnected) {
            Crisp.session.setData(playerCrispData)
          }
          Crisp.chat.open()
          Crisp.chat.show()
          handleClose()
        }
      }
    }
  }

  const navContent = (item: MobileNavItem, index: number) => {
    return (
      <BottomNavigationAction
        key={index}
        label={t(item.label)}
        icon={<FontAwesomeIcon icon={item.icon} fixedWidth />}
        sx={{
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          if (mobileNavigation?.index === index) {
            handleClose()
          } else {
            if (item.label === 'nav.support') {
              handleSupportClick()
            } else {
              dispatch(
                showMobileNavigation({
                  index,
                  open: true,
                })
              )
            }
          }

          // scroll block to top MobileNavDrawer
          const drawerPaperElement = document.querySelector('.MuiDrawer-paper')
          if (drawerPaperElement) {
            drawerPaperElement.scrollTop = 0
          }
        }}
      />
    )
  }

  return (
    <>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          svg: { fontSize: 20 },
          zIndex: 999,

          '*': {
            fontFamily: `${
              i18n.dir(i18n.language) === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
            }, sans-serif !important`,
          },

          '.MuiBottomNavigationAction-label': {
            fontSize: '12px !important',
            fontWeight: 700,
            mt: 0.75,
          },
          '.MuiBottomNavigation-root': {
            position: 'relative',
            height: 74,
            zIndex: 9999,
            py: 2,
            background: colors.gray[3],
          },
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={mobileNavigation?.index}
          // onChange={(event, newValue) => {
          //   setValue(newValue)
          // }}
          sx={{ display: pathname.includes('play') ? 'none' : 'flex' }}
        >
          {mobileNavList.map((item, index) =>
            item.isProtected
              ? isLoggedIn && navContent(item, index)
              : item.forGuestUser
              ? !isLoggedIn && navContent(item, index)
              : navContent(item, index)
          )}
        </BottomNavigation>
        <MobileNavDrawer onClose={handleClose} />
      </Paper>
    </>
  )
}

export const MobileNavDrawer = (props: DrawerProps) => {
  const { mobileNavigation } = useAppSelector(getUi)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)
  const pathname = useLocation().pathname
  const innerHeight = window.innerHeight

  let contentList = [
    <Games />,
    <Login />,
    <Wallet />,
    <Box
      sx={{
        '.banner': {
          p: 0,
          background: 'none',
          border: 'none',
          '& > .MuiStack-root': { gap: 0 },
          // '.table--container': { maxHeight: 'initial !important' },
        },
      }}
    >
      <LiveTournamentStatus />
      {/* <TournamentHistory /> */}
    </Box>,
    <LiveSupport />,
    <More />,
  ]

  return (
    <>
      <Drawer
        anchor='bottom'
        open={mobileNavigation?.open}
        onClose={props.onClose}
        keepMounted
        sx={{
          body: {
            overflowY: 'hidden',
          },
          '.MuiDrawer-paper': {
            overscrollBehavior: 'none',
            borderTop: `2px solid ${colors.gray[4]} !important`,
            borderRadius: 'initial !important',
            background:
              'linear-gradient(297deg, #2A243A 56.61%, #110C20 98.21%)',
            zIndex: 2,
            bottom: pathname.includes('play') ? 0 : 74,
            '*': {
              fontFamily: `${
                i18n.dir(i18n.language) === 'rtl'
                  ? 'Iranyekan'
                  : '"Nunito Sans"'
              }, sans-serif !important`,
            },
          },
          ...props.sx,
        }}
        disablePortal
      >
        <Stack
          sx={{
            background: colors.gray[3],
            height: 38,
            paddingInlineStart: 3,
            py: 1.5,
            position: 'sticky',
            top: '-1px',
            zIndex: 99,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: -1,
              right: 16,
              left: 'initial',
              svg: { fontSize: 24 },
            }}
            onClick={(e) => {
              if (props.onClose) props.onClose(e, 'backdropClick')
            }}
          >
            <FontAwesomeIcon icon={faRectangleXmark} color={colors.gray[12]} />
          </IconButton>
        </Stack>
        <Box>
          <Stack
            sx={{
              height: innerHeight < 740 ? '57vh' : '65vh',
              p: 3,
              '.bottomNavContent': {
                visibility: 'visible',
                '&.active': { visibility: 'none' },
              },
            }}
          >
            {/* Render content here */}
            {/* {activeContent} */}
            {contentList.map((item, index) => (
              <Box
                sx={{
                  display: index === mobileNavigation?.index ? 'block' : 'none',
                  height: 1,
                }}
                key={index}
              >
                {item}
              </Box>
            ))}
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}

export default MobileBottomNav
