import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { gameplayBreakpoint } from '@shared/constants'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { funLaunchApi } from 'apis/funLaunchApi'
import { Crisp } from 'crisp-sdk-web'
import { messagesListener } from 'handlers/gameHandlers/gameEventListener'
import usePlayerCrispData from 'hooks/usePlayerCrispData'
import useT from 'hooks/useTranslation'
import FunPlayButton from 'parsroyal/components/FunPlayButton/FunPlayButton'
import LoginRegister from 'parsroyal/components/Header/components/LoginRegister'
import ReloadGameModal from 'parsroyal/components/Modals/ReloadGame/ReloadGameModal'
import { LiveTournamentStatus } from 'parsroyal/components/Tournament/LiveTournamentStatus'
import TournamentHistory from 'parsroyal/components/Tournament/TournamentHistory'

import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  getGame,
  initGame,
  removeActiveGame,
  setActiveGame,
} from 'store/reducers/gameSlice'
import { getGlobal } from 'store/reducers/globalSlice'
import { getUi } from 'store/reducers/uiSlice'
import { v4 as uuidv4 } from 'uuid'

export interface GameProps {
  gameId: string
  gameplay: string
  extraData?: object
}

const minMediaQuery = `(min-width: ${gameplayBreakpoint}px)`
const maxMediaQuery = `(max-width: ${gameplayBreakpoint - 1}px)`
const minMediaQueryList = window.matchMedia(minMediaQuery)
const maxMediaQueryList = window.matchMedia(maxMediaQuery)

function Game() {
  // const { gameId, gameplay, extraData } = props
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up(gameplayBreakpoint))
  const tabletLandscapeBreakpoint = useMediaQuery(
    theme.breakpoints.between(1060, 1062)
  )
  const game = useAppSelector(getGame)
  const { player } = useAppSelector(getGlobal)
  const { generalConfig, gameList } = useAppSelector(getUi)
  const { locale, gameSlug } = useParams()
  const [loading, setLoading] = useState(true)
  const alienContext = useContext(AlienContext)
  const dispatch = useAppDispatch()
  const { t } = useT()
  const selectedGame = gameList.find((item) => gameSlug === item.slug)
  const gameplay = selectedGame?.slug
  const extraData = selectedGame?.extraData
  const gameId = selectedGame?.gameId

  const [searchParams, setSearchParams] = useSearchParams()
  const isFunPlay = searchParams.get('funPlay')
  const playerCrispData = usePlayerCrispData()
  const [reloadGame, setReloadGame] = useState(false)

  useEffect(() => {
    dispatch(removeActiveGame())
  }, [])

  // Reload page on breakpoint trigger
  const [match, updateMatch] = useState(false)
  useEffect(() => {
    const handleChange = (ev: MediaQueryListEvent) => {
      updateMatch(ev.matches)
    }
    minMediaQueryList.addEventListener('change', handleChange)
    maxMediaQueryList.addEventListener('change', handleChange)
    return () => {
      // This is called the cleanup phase aka beforeUnmount
      minMediaQueryList.removeEventListener('change', handleChange)
      maxMediaQueryList.removeEventListener('change', handleChange)
    }
  }, []) // Only do this once, aka hook-ish way of saying didMount

  useEffect(() => {
    if (match && window.location.pathname.includes('play')) {
      if (game.activeGameId) {
        alienContext?.client.closeGame(
          { gameId: game.activeGameId },
          { uuid: uuidv4() }
        )
      }
      if (gameId) {
        if (isFunPlay) {
          getFunPlayURL(player?.nickname)
        } else {
          getGameUrl()
        }
      }
    }
  }, [match])

  useEffect(() => {
    if (gameId) {
      if (isFunPlay) {
        getFunPlayURL(player?.nickname)
      } else {
        getGameUrl()
      }
    }
  }, [
    alienContext?.isSocketConnected,
    locale,
    isFunPlay,
    gameId,
    generalConfig.funLaunchUrl,
  ])

  useEffect(() => {
    if (reloadGame) {
      getGameUrl()
      setReloadGame(false)
    }
  }, [reloadGame])

  const getGameUrl = () => {
    if (alienContext?.isSocketConnected && gameplay && gameId) {
      alienContext?.client.getGameLaunch(
        {
          gameId,
          extraData: {
            gameplay,
            lang: locale || 'en',
            ...extraData,
          },
        },
        {
          uuid: uuidv4(),
          meta: {
            tabId: window.tabId,
          },
        }
      )
      dispatch(setActiveGame(selectedGame.gameId))
    }

    if (game.status) {
      if (alienContext?.isSocketConnected) {
        Crisp.session.setData(playerCrispData)
      }
    }

    searchParams.delete('reloadGame')
  }

  const getFunPlayURL = async (nickname?: string) => {
    try {
      if (generalConfig.funLaunchUrl && gameId) {
        const res = await funLaunchApi(generalConfig.funLaunchUrl, {
          gameId,
          currency: 'USD',
          balance: 1000,
          lang: locale || 'en',
          nickname,
          extraData,
        })

        dispatch(removeActiveGame())
        dispatch(initGame(res))
        if (gameId) dispatch(setActiveGame(gameId))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const gameLoaded = () => {
    setLoading(false)
    messagesListener()
  }

  return (
    <>
      <Helmet>
        <style type='text/css'>{`
        #FooterBox {
          display: none
        }
        @media screen and (min-width: ${theme.breakpoints.values.lg}px) and (min-height: 660px) {    
          body {
              // overflow: hidden;
            }
          body {
            background: ${colors.gray[2]} !important
          }
        }

  
     `}</style>
      </Helmet>
      {alienContext?.isSocketConnected || isFunPlay ? (
        <>
          {game?.url && breakpoint && !tabletLandscapeBreakpoint ? (
            // Desktop
            <>
              <Box
                component='span'
                id='iframeContainer'
                sx={{ height: gameplay === 'jogodobicho' ? '100vh' : 682 }}
              >
                <iframe
                  title='game'
                  frameBorder={'none'}
                  src={game.url}
                  id='gameIframe'
                  onLoad={gameLoaded}
                  style={{
                    display: loading ? 'none' : 'block',
                    width: '100%',
                    height: gameplay === 'jogodobicho' ? '100vh' : 682,
                  }}
                  className='nonSelect'
                />
              </Box>
              <Container
                sx={{
                  pt: 1,
                  pb: 6,
                  px: { md: 1.5, lg: 3 },
                }}
              >
                <Box sx={{ maxWidth: { lg: 1200 }, m: 'auto' }}>
                  <LiveTournamentStatus />
                </Box>
              </Container>
            </>
          ) : (
            // Mobile
            <iframe
              title='game'
              frameBorder={'none'}
              src={game.url}
              id='gameIframe'
              onLoad={gameLoaded}
              style={{
                display: loading ? 'none' : 'block',
                width: '100%',
                height: '100vh',
              }}
              className='nonSelect'
            />
          )}
          <Backdrop open={loading} transitionDuration={{ exit: 1000 }}>
            <CircularProgress color='primary' role='presentation' />
          </Backdrop>
        </>
      ) : (
        <>
          <Stack
            sx={{
              height: 1,
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Card
              sx={{
                p: 2,
                m: 1,
                borderRadius: 3,
                border: `2px solid ${colors.gray[4]}`,
                background:
                  'linear-gradient(297.11deg, #2A243A 56.61%, #110C20 98.21%)',
              }}
            >
              <Stack
                sx={{
                  gap: 1.5,
                  alignItems: 'center',
                  height: 1,
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant='headline'
                  sx={{ textTransform: 'capitalize', textAlign: 'center' }}
                >
                  {t('general.join_us_to_play')}
                </Typography>
                <LoginRegister />
                {generalConfig.showFunLaunch && gameplay && (
                  <>
                    OR
                    <FunPlayButton slug={gameplay} />
                  </>
                )}
              </Stack>
            </Card>
          </Stack>
        </>
      )}
      <ReloadGameModal reloadGame={reloadGame} setReloadGame={setReloadGame} />
    </>
  )
}

export default Game
