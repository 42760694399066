import {
  PaymentGatewayBaseConfig,
  PaymentGatewayName,
} from '@alienclient/back-front'
import { Typography } from '@mui/material'

import { useAppSelector } from 'store/hooks'
import { getGlobal } from 'store/reducers/globalSlice'
import { CurrencySelectProps } from '../CurrencySelect'
import { PaymentGatewayProps } from '../PaymentGatewaySelect'
import { TomanInput } from './Input/TomanInput'

import useT from 'hooks/useTranslation'
import { CryptoInput } from './Input/CryptoInput'

const WithdrawInputs = (props: PaymentGatewayProps & CurrencySelectProps) => {
  const { paymentGateway, currency } = props
  const { t } = useT()
  const global = useAppSelector(getGlobal)
  const wallet = global.player?.wallet
  const activeCurrency = global.player?.activeCurrency

  if (!activeCurrency) return <></>
  const activeCurrencyObj = wallet?.byCurrency[activeCurrency]

  const minAmount = // @ts-ignore
  (global.paymentGateways?.[paymentGateway] as PaymentGatewayBaseConfig)
    ?.withdrawLimitByCurrency?.[activeCurrency]?.minAmount
  const feeAmount = // @ts-ignore
  (global.paymentGateways?.[paymentGateway] as PaymentGatewayBaseConfig)
    ?.withdrawFeeByCurrency?.[activeCurrency]?.amount

  return (
    <>
      {activeCurrencyObj ? (
        <>
          {paymentGateway === PaymentGatewayName.CPG && (
            <CryptoInput
              {...props}
              activeCurrency={activeCurrency}
              activeCurrencyObj={activeCurrencyObj}
              minAmount={minAmount || 0}
              feeAmount={feeAmount || 0}
            />
          )}

          {paymentGateway === PaymentGatewayName.PW && (
            <TomanInput
              {...props}
              activeCurrency={activeCurrency}
              activeCurrencyObj={activeCurrencyObj}
              minAmount={minAmount || 0}
              feeAmount={feeAmount || 0}
            />
          )}
          {paymentGateway === PaymentGatewayName.JB && (
            <TomanInput
              {...props}
              activeCurrency={activeCurrency}
              activeCurrencyObj={activeCurrencyObj}
              minAmount={minAmount || 0}
              feeAmount={feeAmount || 0}
            />
          )}
        </>
      ) : (
        <Typography variant='subHeadline' sx={{ textAlign: 'center' }}>
          {t('wallet.only_IRT')}
        </Typography>
      )}
    </>
  )
}

export default WithdrawInputs
