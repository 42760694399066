import {
  InitInfo,
  Notification,
  OnDisconnectedParams,
} from '@alienclient/back-front'
import { NotificationSeverity } from '@shared/constants'
import { removeCookie } from 'helpers/helpers'
import { removeActiveGame } from 'store/reducers/gameSlice'
import { resetGlobal, setGlobal } from 'store/reducers/globalSlice'
import {
  hideBetsSideDrawer,
  hideLoginModal,
  hideMobileNavigation,
  hideNotificationSideDrawer,
  hidePopupModal,
  hideSideDrawer,
  hideSupportModal,
  hideTransactionSideDrawer,
  hideVerificationSideDrawer,
  hideWalletModal,
  showToast,
} from 'store/reducers/uiSlice'
import store from 'store/store'

export class EventsHandler {
  async handleInit(initInfo: InitInfo) {
    store.dispatch(setGlobal(initInfo))
    removeCookie('refId')
    removeCookie('affId')
    removeCookie('channelId')
  }
  async handleLogout() {
    store.dispatch(resetGlobal())
    store.dispatch(hideLoginModal())
    store.dispatch(hideWalletModal())
    store.dispatch(hideMobileNavigation())
    store.dispatch(hideTransactionSideDrawer())
    store.dispatch(hideNotificationSideDrawer())
    store.dispatch(hideVerificationSideDrawer())
    store.dispatch(hideSupportModal())
    store.dispatch(hidePopupModal())
    store.dispatch(hideBetsSideDrawer())
    store.dispatch(hideSideDrawer())
    store.dispatch(removeActiveGame())
    window.isTelegramCalledOnce = false
    if (window.location.pathname.includes('play')) {
      localStorage.removeItem('auth')
      // test
      window.location.reload()
    }
  }

  async handleNotification(notification: Notification) {
    let severity:
      | 'warning'
      | 'info'
      | 'error'
      | 'default'
      | 'success'
      | undefined
    switch (notification.severity) {
      case NotificationSeverity.ALERT:
        severity = 'warning'
        break
      case NotificationSeverity.INFORMATION:
        severity = 'info'
        break
      case NotificationSeverity.ERROR:
        severity = 'error'
        break
      case NotificationSeverity.VERBOSE:
        severity = 'default'
        break
      case NotificationSeverity.WARNING:
        severity = 'warning'
        break

      default:
        severity = 'default'
        break
    }

    store.dispatch(
      showToast({
        show: true,
        message: notification.data,
        severity,
      })
    )
  }
}
