import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getUi, hideLoginModal } from 'store/reducers/uiSlice'
import { ModalComponent } from '..'
import ReloadGame, { ReloadGameProps } from './ReloadGame'
import useT from 'hooks/useTranslation'

const ReloadGameModal = (props: ReloadGameProps) => {
  const { reloadGameModal } = useAppSelector(getUi)
  const dispatch = useAppDispatch()
  const { t } = useT()

  return (
    <ModalComponent
      open={reloadGameModal.open}
      title={t('games.game_reload')}
      onClose={() => dispatch(hideLoginModal())}
      keepMounted
    >
      <ReloadGame {...props} />
    </ModalComponent>
  )
}

export default ReloadGameModal
