import {
  Container,
  ContainerProps,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { navDrawerWidth } from '@shared/constants'
import { colors } from '@theme'
import useBreakpoint from 'hooks/useBreakpoint'
import useT from 'hooks/useTranslation'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

interface PageContentProps extends PropsWithChildren {
  title?: string
  subtitle?: string
}

const PageContent = (props: PageContentProps & ContainerProps) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useT()
  const currentBreakpoint = useBreakpoint().getBreakPointName()
  return (
    <Container
      fixed
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *:not(:first-child):not(:last-child)': {
          my: 1.5,
        },
        py: { xs: 3, sm: 6 },
        px: { xs: 1, sm: 3 },
        ...(currentBreakpoint === 'xl'
          ? { maxWidth: '1200px !important' }
          : currentBreakpoint && {
              maxWidth: `${
                theme.breakpoints.values[currentBreakpoint] - navDrawerWidth
              }px !important`,
            }),

        ...props.sx,
      }}
    >
      <Stack>
        {props.title && (
          <Typography
            variant={sm ? 'title2' : 'title3'}
            sx={{ color: colors.gray[12], textAlign: 'center' }}
          >
            {t(props.title)}
          </Typography>
        )}
        {props.subtitle && (
          <Typography variant='bodyMedium' sx={{ textAlign: 'center' }}>
            {t(props.subtitle)}
          </Typography>
        )}
      </Stack>
      {props.children}
    </Container>
  )
}

export default PageContent
