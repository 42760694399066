import {
  faArrowLeft,
  faArrowRight,
  faRectangleXmark,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AppBar,
  Box,
  IconButton,
  Modal,
  ModalProps,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { colors, gradients, shadows } from '@theme'
import { ModalCrashProps } from '@type'
import useT from 'hooks/useTranslation'
import { useTranslation } from 'react-i18next'

export const StyledModalBox = styled(Box)(() => ({
  background: gradients['5'],
}))

export const ModalComponent = ({
  radius = 16,
  width = 350,
  height = 'auto',
  variant = 1,
  closeable = true,
  ...props
}: ModalProps & ModalCrashProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const { i18n } = useTranslation()
  const { t } = useT()
  const dir = i18n.dir(i18n.language)

  return (
    <Modal
      sx={{
        overflow: 'hidden',
        visibility: props.open ? 'visible' : 'hidden',
        m: 0.75,
        '*': {
          fontFamily: `${
            i18n.dir(i18n.language) === 'rtl'
              ? 'Iranyekan Light'
              : '"Nunito Sans"'
          }, sans-serif !important`,
        },
        ...props.sx,
      }}
      open={props.open}
      onClose={props.onClose}
      keepMounted={props.keepMounted}
    >
      <StyledModalBox
        dir={dir}
        sx={{
          body: {
            overflowY: 'hidden',
          },
          border: `2px solid ${colors.gray[4]}`,
          boxShadow: shadows[0],
          borderRadius: `${radius}px`,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1,
          ':focus-visible': {
            outline: `none`,
          },

          height,
          maxHeight: '85%',
          maxWidth: width,
          overflowY: 'scroll',
          background:
            'linear-gradient(297.11deg, #2A243A 56.61%, #110C20 98.21%)',
        }}
      >
        {props.title ? (
          <AppBar position='sticky'>
            <Toolbar
              sx={{
                background: colors.gray[3],
                minHeight: '58px !important',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifySelf: 'flex-start',
                  gap: 1,
                  color: colors.gray[11],
                  svg: {
                    fontSize: 16,
                  },
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  width: 1,
                }}
              >
                {props.showBackButton && (
                  <IconButton
                    onClick={(e) =>
                      props.onClose && props.onClose(e, 'escapeKeyDown')
                    }
                    sx={{ marginInlineStart: -1 }}
                  >
                    <FontAwesomeIcon
                      icon={dir === 'rtl' ? faArrowRight : faArrowLeft}
                      fontSize={20}
                    />
                  </IconButton>
                )}
                {props.icon && <FontAwesomeIcon icon={props.icon} />}
                {props.title && (
                  <Typography variant='headline'>{t(props.title)}</Typography>
                )}
                {props.showCloseButton && (
                  <IconButton
                    // TODO: Replace after adding ui slice
                    id={`closeModalBtn${props.title ? props.title : ''}`}
                    sx={{
                      marginInlineStart: 'auto',
                      marginInlineEnd: -1,
                      svg: { fontSize: 20 },
                      position: 'absolute',
                      ...(!props.title && { top: 6 }),
                      ...(dir === 'rtl' ? { left: 0 } : { right: 0 }),
                    }}
                    onClick={(e) =>
                      props.onClose && props.onClose(e, 'escapeKeyDown')
                    }
                  >
                    <FontAwesomeIcon icon={faRectangleXmark} />
                  </IconButton>
                )}
              </Box>
            </Toolbar>
          </AppBar>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifySelf: 'flex-start',
              gap: 2,
              color: colors.gray[11],
              svg: {
                fontSize: 16,
              },
              position: 'sticky',
              top: 0,
              zIndex: 1,
              width: 1,
            }}
          >
            {props.showBackButton && (
              <IconButton
                onClick={(e) =>
                  props.onClose && props.onClose(e, 'escapeKeyDown')
                }
              >
                <FontAwesomeIcon
                  icon={dir === 'rtl' ? faArrowRight : faArrowLeft}
                  fontSize={20}
                />
              </IconButton>
            )}
            {props.icon && <FontAwesomeIcon icon={props.icon} />}
            {props.title && (
              <Typography variant='headline'>{t(props.title)}</Typography>
            )}
            {props.showCloseButton && (
              <IconButton
                // TODO: Replace after adding ui slice
                id={`closeModalBtn${props.title ? props.title : ''}`}
                sx={{
                  marginInlineStart: 'auto',
                  svg: { fontSize: 20 },
                  position: 'absolute',
                  ...(!props.title && { top: 6 }),
                  ...(dir === 'rtl' ? { left: 10 } : { right: 10 }),
                }}
                onClick={(e) =>
                  props.onClose && props.onClose(e, 'escapeKeyDown')
                }
              >
                <FontAwesomeIcon icon={faRectangleXmark} />
              </IconButton>
            )}
          </Box>
        )}
        <Stack
          sx={{
            height: '90%',
            overflow: 'scroll',
            position: 'relative',
            '.MuiToolbar-root': {
              padding: '16px',
            },
          }}
        >
          <Stack sx={{ flex: 1, pt: 2.5, p: 2 }}>{props.children}</Stack>
        </Stack>
      </StyledModalBox>
    </Modal>
  )
}
