import { AuthResult } from '@alienclient/back-front'
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
} from '@mui/material'
import { colors } from '@theme'
import { AlienContext } from 'App'
import { alienClientEventsReceiver } from 'handlers/eventsReceiver'
import numeral from 'numeral'
import { FC, useContext } from 'react'

export const connectSocket = async (result: AuthResult) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  try {
    await alienClientEventsReceiver(result)
    // You can add additional code here that executes after eventsReceiver is successful.
    if (result) localStorage.setItem('auth', JSON.stringify(result))
  } catch (error) {
    console.error('An error occurred:', error)
    // You can handle the error here, such as displaying an error message to the global.
  }
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#13101E!important`,
    color: colors.gray[6] || theme.palette.grey[300],
    borderBottom: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: 'white',
    borderBottom: 0,
    fontWeight: 700,
  },
}))
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(74, 71, 84, 0.1)' : colors.gray[11],
    borderBottom: 0,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const TransparentButton = ({ ...rest }) => {
  return (
    <Button
      {...rest}
      sx={{
        px: 2,
        py: [0.5, 1, 1],
        backgroundColor: 'transparent',
        border: '1px solid #fff',
        borderRadius: '16px',
      }}
    >
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '700',
          color: 'white',
        }}
      >
        {rest.children}
      </Typography>
    </Button>
  )
}

export const ContainedButton = ({ ...rest }) => {
  return (
    <Button
      {...rest}
      variant='contained'
      sx={{
        px: 2,
        py: 1,
        borderRadius: '16px',
        width: '100%',
      }}
      dir='rtl'
    >
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '700',
          color: 'white',
        }}
      >
        {rest.children}
      </Typography>
    </Button>
  )
}

export const SeparatorWithText = ({ ...rest }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={1.1}>
      <Stack
        sx={{
          border: `1px solid ${colors.gray[4]}`,
          width: '45px',
        }}
      ></Stack>
      <Typography variant='bodySmall' sx={{}}>
        {rest.children}
      </Typography>
      <Stack
        sx={{
          border: `1px solid ${colors.gray[4]}`,
          width: '45px',
        }}
      ></Stack>
    </Stack>
  )
}

export const TimerItem = (props: { title: string; value: number }) => {
  return (
    <Stack
      sx={{
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '27px',
        height: '34px',
      }}
    >
      <Typography
        sx={{
          color: '#22E64B',
          fontSize: '14px',
        }}
      >
        {props.value}
      </Typography>
      <Typography
        sx={{
          color: '#22E64B',
          fontSize: '8px',
          fontWeight: '400',
        }}
      >
        {props.title}
      </Typography>
    </Stack>
  )
}

export const OddBadge = (props: { value: number }) => {
  return (
    <Stack
      sx={{
        background: '#07932C',
        height: '26px',
        width: [58, 62],
        px: 1,
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          color: '#fff',
          fontSize: [12, 14],
          fontWeight: '700',
        }}
      >
        {numeral(props.value).format('0.0[0]')}x
      </Typography>
    </Stack>
  )
}

export const MultiplierBox = (props: { title: string; value: number }) => {
  return (
    <Stack
      direction={'row'}
      gap={'5px'}
      sx={{
        background: '#1D1929',
        padding: '4px 6px',
        borderRadius: '8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '37px',
        width: 'fit-content',
      }}
    >
      <Typography sx={{ color: '#968EB0' }}>{props.title}:</Typography>
      <Typography>{props.value}x</Typography>
    </Stack>
  )
}

export const BoxedValue = (props: { value: number | string }) => {
  return (
    <Stack
      direction={'row'}
      gap={'5px'}
      sx={{
        background: '#1D1929',
        padding: '4px 6px',
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '37px',
        width: 'fit-content',
        minWidth: '30px',
      }}
    >
      <Typography>{props.value}</Typography>
    </Stack>
  )
}

export const PlayingTableContainer = ({ ...props }) => {
  const theme = useTheme()

  return (
    <TableContainer
      sx={{
        bgcolor:
          theme.palette.mode === 'dark' ? colors.gray[3] : colors.gray[11],

        borderRadius: '12px',
        // borderBottomLeftRadius: 0,
        // borderEndEndRadius: 0,
        minHeight: '306px',
        height: '327px',
      }}
      className={'nonSelect'}
    >
      {props.children}
    </TableContainer>
  )
}
export const PlayingTableHeadRow: FC<TableCellProps> = ({ ...props }) => {
  const theme = useTheme()

  return (
    <StyledTableCell
      {...props}
      sx={{
        px: 1,
        py: 0.2,
        // textAlign: isRtl ? 'right' : 'left',
        [`&.${tableCellClasses.head}`]: {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? colors.gray[3] + ' !important'
              : colors.gray[10] + ' !important',
          minWidth: 'inherit',
        },
      }}
    >
      <Typography
        variant={'caption'}
        fontSize={12}
        color={theme.palette.mode === 'dark' ? '#837C9C' : colors.gray[7]}
      >
        {props.children}
      </Typography>
    </StyledTableCell>
  )
}

export const PlayingTableBody = ({ ...props }) => {
  return (
    <TableBody
      // dir={isRtl ? "rtl" : "ltr"}
      sx={{
        background: 'rgba(74, 71, 84, 0.1)',
        '.MuiTableCell-body': {
          padding: '0 16px',
          height: '24px',
        },
      }}
    >
      {props.children}
    </TableBody>
  )
}

export const PlayingTableRow = ({ ...props }) => {
  return (
    <StyledTableRow
      {...props}
      // key={index}

      className={''}
      sx={{
        '&.status-won': {
          // bgcolor: '#BFFFCA',
          '.nicknameBet': {
            color: '#0FC943 !important',
          },
          '.betAmount': {
            color: '#0FC943 !important',
            fontWeight: 700,
          },
          '.betWin': {
            color: '#0FC943 !important',
            fontWeight: 700,
          },
          '.betOdds': {
            color: '#0FC943 !important',
            fontWeight: 700,
          },
        },
        '&.status-lost': {
          '.nicknameBet': {
            color: '#FF6768 !important',
          },
          '.betAmount': {
            color: '#FF6768 !important',
            fontWeight: 700,
          },
          '.betWin': {
            color: '#FF6768 !important',
            fontWeight: 700,
          },
          '.betOdds': {
            color: '#FF6768 !important',
            fontWeight: 700,
          },
        },
      }}
    >
      {props.children}
    </StyledTableRow>
  )
}

export const PlayingTableCell: FC<TableCellProps> = ({ ...props }) => {
  const theme = useTheme()

  return (
    <StyledTableCell
      {...props}
      component='th'
      scope='row'
      sx={{
        borderBottom: 0,
        color: '#FB8111 !important',
        fontWeight: '600 !important',
        textAlign: 'center',
      }}
    >
      <Typography
        className={'nickname'}
        variant={'caption'}
        color={theme.palette.mode === 'dark' ? '#FB8111' : '#FB8111'}
      >
        {props.children}
      </Typography>
    </StyledTableCell>
  )
}

export const FirstPlaceCard = ({ ...rest }) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        background:
          'linear-gradient(89.71deg, #FFC700 -10.78%, rgba(255, 199, 0, 0) 32.05%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
        width: '100%',
        height: '42px',
        borderRadius: '8px',
        position: 'relative',
      }}
    >
      {rest.children}
    </Stack>
  )
}

export const SecondPlaceCard = ({ ...rest }) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        background:
          'linear-gradient(89.69deg, #393939 -27.42%, rgba(160, 159, 157, 0.863636) -18.56%, rgba(142, 142, 142, 0) 37.54%, rgba(124, 124, 124, 0) 85.95%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
        width: '100%',
        height: '42px',
        borderRadius: '8px',
        position: 'relative',
      }}
    >
      {rest.children}
    </Stack>
  )
}

export const ThirdPlaceCard = ({ ...rest }) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        background:
          'linear-gradient(89.69deg, #744111 -27.42%, rgba(189, 102, 53, 0.863636) -18.56%, rgba(252, 123, 82, 0) 37.54%, rgba(124, 124, 124, 0) 85.95%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
        width: '100%',
        height: '42px',
        borderRadius: '8px',
      }}
    >
      {rest.children}
    </Stack>
  )
}

export const NormalRankCard = ({ ...rest }) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        background:
          'linear-gradient(90.67deg, rgba(69, 48, 138, 0.2) 1.82%, rgba(43, 37, 59, 0) 50.58%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
        width: '100%',
        height: '42px',
        borderRadius: '8px',
      }}
    >
      {rest.children}
    </Stack>
  )
}

export const PlayerRankCard = ({ ...rest }) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        background: '#2A243A',
        width: '100%',
        height: '60px',
        borderRadius: '16px',
        position: 'relative',
        bottom: '8px',
      }}
    >
      {rest.children}
    </Stack>
  )
}
