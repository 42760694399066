import { InitInfo } from '@alienclient/back-front'
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  Footer,
  Game,
  GeneralConfig,
  GeneralConfigWithLocales,
  GeneralTranslation,
  LoginInfo,
  Page,
  TournamentSection,
  VerificationType,
  WalletConfig,
  WalletInfo,
} from 'parsroyal/types'
import { RootState } from 'store/store'

interface ModalProps {
  open: boolean
  title: string
  icon?: IconDefinition
}

interface SideDrawerProps {
  open: boolean
}

interface MobileNavigationProps {
  open: boolean
  index: number | null
}

interface ToastProps {
  show: boolean
  message?: string
  message2?: string
  severity?: 'default' | 'error' | 'success' | 'warning' | 'info'
}
interface UISliceProps {
  lang: string
  generalConfigWithLocales: GeneralConfigWithLocales
  generalConfig: GeneralConfig
  tournamentSection: TournamentSection
  footer: Footer
  pageList: Page[]
  gameList: Game[]
  toast: ToastProps
  modal: ModalProps | null
  // Separate property for each modal
  loginModal: { open: boolean }
  walletModal: { open: boolean; activeTab?: 'deposit' | 'withdraw' }
  supportModal: { open: boolean }
  popupModal: { open: boolean }
  reloadGameModal: { open: boolean }
  betsSideDrawer: { open: boolean }
  transactionSideDrawer: { open: boolean }
  notificationSideDrawer: { open: boolean }
  verificationSideDrawer: { open: boolean }
  mobileNavigation: MobileNavigationProps | null
  activeNavItem: string
  sideDrawer: SideDrawerProps | null
  walletInfoList: WalletInfo[]
  loginInfoList: LoginInfo[]
  walletConfig: WalletConfig
  verification: VerificationType
}

const initialState: UISliceProps = {
  lang: 'en',
  generalConfigWithLocales: {},
  generalConfig: {},
  tournamentSection: {},
  footer: {},
  pageList: [],
  gameList: [],
  modal: null,
  loginModal: { open: false },
  walletModal: { open: false },
  supportModal: { open: false },
  popupModal: { open: false },
  reloadGameModal: { open: false },
  betsSideDrawer: { open: false },
  transactionSideDrawer: { open: false },
  notificationSideDrawer: { open: false },
  verificationSideDrawer: { open: false },
  mobileNavigation: null,
  activeNavItem: '',
  sideDrawer: null,
  toast: { show: false },
  walletInfoList: [],
  loginInfoList: [],
  walletConfig: {},
  verification: {},
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUi(state, action: PayloadAction<InitInfo>) {
      Object.assign(state, action.payload)
    },
    resetUi: () => {
      return initialState
    },
    setLang(state, action: PayloadAction<string>) {
      state.lang = action.payload
    },
    resetLang(state) {
      state.lang = initialState.lang
    },
    storeGeneralConfigWithLocales(
      state,
      action: PayloadAction<GeneralConfigWithLocales>
    ) {
      state.generalConfigWithLocales = action.payload
    },
    storeGeneralConfig(state, action: PayloadAction<GeneralConfig>) {
      state.generalConfig = action.payload
    },
    storeTournamentSection(state, action: PayloadAction<TournamentSection>) {
      state.tournamentSection = action.payload
    },
    storeFooter(state, action: PayloadAction<Footer>) {
      state.footer = action.payload
    },
    storePageList(state, action: PayloadAction<Page[]>) {
      state.pageList = action.payload
    },
    storeGameList(state, action: PayloadAction<Game[]>) {
      state.gameList = action.payload
    },
    storeWalletInfoList(state, action: PayloadAction<WalletInfo[]>) {
      state.walletInfoList = action.payload
    },
    storeLoginInfoList(state, action: PayloadAction<LoginInfo[]>) {
      state.loginInfoList = action.payload
    },
    storeWalletConfig(state, action: PayloadAction<WalletConfig>) {
      state.walletConfig = action.payload
    },
    storeVerification(state, action: PayloadAction<VerificationType>) {
      state.verification = action.payload
    },
    showToast(state, action: PayloadAction<ToastProps>) {
      state.toast = action.payload
    },
    clearToast(state) {
      state.toast = initialState.toast
    },
    showModal(state, action: PayloadAction<ModalProps>) {
      return { ...state, modal: action.payload }
    },
    hideModal(state) {
      return { ...state, modal: null }
    },
    showMobileNavigation(state, action: PayloadAction<MobileNavigationProps>) {
      // Disable background scrolling for ios
      if (document.body.style.position !== 'fixed') {
        const scrollY = window.scrollY
        document.body.dataset.scrollY = scrollY.toString()
        document.body.style.top = `-${scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
      }

      return { ...state, mobileNavigation: action.payload }
    },

    hideMobileNavigation(state) {
      // Enable background scrolling
      document.body.style.top = 'initial'
      document.body.style.position = 'initial'
      document.body.style.width = 'initial'
      const scrollY = document.body.dataset.scrollY
      if (scrollY) window.scroll(0, parseInt(scrollY))
      document.body.removeAttribute('data-scroll-y')

      return { ...state, mobileNavigation: null }
    },
    showLoginModal(state) {
      return { ...state, loginModal: { open: true } }
    },
    hideLoginModal(state) {
      return { ...state, loginModal: { open: false } }
    },
    showPopupModal(state) {
      return { ...state, popupModal: { open: true } }
    },
    hidePopupModal(state) {
      return { ...state, popupModal: { open: false } }
    },
    showReloadGameModal(state) {
      return { ...state, reloadGameModal: { open: true } }
    },
    hideReloadGameModal(state) {
      return { ...state, reloadGameModal: { open: false } }
    },
    showWalletModal(
      state,
      action: PayloadAction<{ activeTab?: 'deposit' | 'withdraw' }>
    ) {
      return {
        ...state,
        walletModal: { open: true, activeTab: action.payload.activeTab },
      }
    },
    hideWalletModal(state) {
      return { ...state, walletModal: { open: false } }
    },
    showSupportModal(state) {
      return { ...state, supportModal: { open: true } }
    },
    hideSupportModal(state) {
      return { ...state, supportModal: { open: false } }
    },
    showSideDrawer(state) {
      return { ...state, sideDrawer: { open: true } }
    },
    hideSideDrawer(state) {
      return { ...state, sideDrawer: null }
    },
    showBetsSideDrawer(state) {
      return { ...state, betsSideDrawer: { open: true } }
    },
    hideBetsSideDrawer(state) {
      return { ...state, betsSideDrawer: { open: false } }
    },
    showTransactionSideDrawer(state) {
      return { ...state, transactionSideDrawer: { open: true } }
    },
    hideTransactionSideDrawer(state) {
      return { ...state, transactionSideDrawer: { open: false } }
    },
    showNotificationSideDrawer(state) {
      return { ...state, notificationSideDrawer: { open: true } }
    },
    hideNotificationSideDrawer(state) {
      return { ...state, notificationSideDrawer: { open: false } }
    },
    showVerificationSideDrawer(state) {
      return { ...state, verificationSideDrawer: { open: true } }
    },
    hideVerificationSideDrawer(state) {
      return { ...state, verificationSideDrawer: { open: false } }
    },
    setActiveNavItem(state, action: PayloadAction<string>) {
      state.activeNavItem = action.payload
    },
    resetActiveNavItem(state) {
      state.activeNavItem = ''
    },
    setComingSoon(state, action: PayloadAction<boolean>) {
      state.generalConfig.showComingSoon = action.payload
    },
  },
})

export const {
  hideBetsSideDrawer,
  hideLoginModal,
  hideMobileNavigation,
  hideModal,
  hideNotificationSideDrawer,
  hideSideDrawer,
  hideTransactionSideDrawer,
  hideWalletModal,
  resetUi,
  setUi,
  showBetsSideDrawer,
  showLoginModal,
  showMobileNavigation,
  showModal,
  showSupportModal,
  hideSupportModal,
  showNotificationSideDrawer,
  showSideDrawer,
  showToast,
  clearToast,
  showTransactionSideDrawer,
  showWalletModal,
  storeGeneralConfigWithLocales,
  storeGeneralConfig,
  storeTournamentSection,
  storeFooter,
  resetActiveNavItem,
  setActiveNavItem,
  setComingSoon,
  storePageList,
  storeGameList,
  storeWalletInfoList,
  storeLoginInfoList,
  storeWalletConfig,
  hidePopupModal,
  showPopupModal,
  hideVerificationSideDrawer,
  showVerificationSideDrawer,
  storeVerification,
  showReloadGameModal,
  hideReloadGameModal,
} = uiSlice.actions

export const getUi = (state: RootState) => state.ui
